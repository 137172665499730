import { List, TextField } from 'react-admin';
import NotificationTypesFilter from './filter';
import DataGridList from 'components/containers/List';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';

export const NotificationTypesList = (props) => (
  <List
    title='NotificationTypes'
    filters={<NotificationTypesFilter />}
    pagination={<Paginator />}
    perPage={15}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
    empty={<EmptyPage create />}
    actions={<ListActions listExporterType='NotificationTypes' />}
  >
    <DataGridList show edit>
      <TextField source='name' />
    </DataGridList>
  </List>
);
