import { FunctionField, ReferenceField } from 'react-admin';
import { Box } from '@material-ui/core';

const AssignStatus = (props) => {
  const { text, message, index } = props;

  return (
    <Box
      sx={{
        textAlign: 'center',
        fontSize: '0.875rem',
        margin: '5px',
        backgroundColor: '#303030',
        padding: '1.5px',
      }}
    >
      {text}
      <ReferenceField
        key={index}
        record={{ expert_id: message.userId }}
        source='expert_id'
        reference='experts'
        label='Expert'
        link={(record, reference) => `/${reference}/${record.expert_id}/show`}
      >
        <FunctionField
          render={(record) => (
            <>{`${record.nickname} (Id: ${message.userId})`}</>
          )}
        />
      </ReferenceField>
    </Box>
  );
};

export default AssignStatus;
