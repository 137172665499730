import { Resource } from 'ra-core';
import MonitorIcon from '@material-ui/icons/Policy';
import conversationViews from './Conversations';

const ExpertsRoutes = [
  <Resource
    key='monitoring'
    name='monitoring'
    icon={MonitorIcon}
    options={{ label: 'Monitoring', isMenuParent: true }}
  />,
  <Resource key='conversation-views' {...conversationViews} />,
];
export default ExpertsRoutes;
