import React from 'react';
import { TextInput } from 'react-admin';
import FilterContainer from 'components/containers/Filter';

const BrandsFilter = (props) => (
  <FilterContainer {...props} variant='standard' margin='normal'>
    <TextInput label='Name' source='name' />
  </FilterContainer>
);

export default BrandsFilter;
