import React from 'react';
import { ViewTitle } from '../../../components/AppBar/Titles';
import { DateField } from 'components/Fields';
import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  ReferenceField,
  FunctionField,
  ChipField,
  useShowController,
} from 'react-admin';

export const ReviewsShow = (props) => {
  const { record } = useShowController(props);
  let reviewable_type = null;
  if (record?.reviewable_type) {
    reviewable_type = record.reviewable_type.toLowerCase();
  }

  return (
    <Show title={<ViewTitle source='id' />} {...props}>
      <SimpleShowLayout>
        <TextField source='id' label='ID' />

        {reviewable_type && (
          <ReferenceField
            source='reviewable_id'
            reference='experts'
            label='Expert'
            fullWidth
          >
            <TextField source='nickname' />
          </ReferenceField>
        )}

        <ReferenceField
          source='reviewer_id'
          reference='users'
          label='User'
          fullWidth
        >
          <TextField source='name' />
        </ReferenceField>

        <TextField source='comment' fullWidth />
        <NumberField source='rating' fullWidth />
        <FunctionField
          sortBy='status'
          label='Status'
          render={(record) => {
            switch (record.status) {
              case 'approved':
                return (
                  <ChipField
                    record={record}
                    source='status'
                    variant='outlined'
                    style={{
                      textTransform: 'capitalize',
                      borderColor: '#44b700',
                    }}
                  />
                );
              case 'rejected':
                return (
                  <ChipField
                    record={record}
                    source='status'
                    variant='outlined'
                    style={{
                      textTransform: 'capitalize',
                      borderColor: '#e62424',
                    }}
                  />
                );
              case 'pending-approval':
                return (
                  <ChipField
                    record={record}
                    source='status'
                    variant='outlined'
                    style={{
                      textTransform: 'capitalize',
                    }}
                  />
                );

              default:
                break;
            }
          }}
        />
        <DateField label='Created At' source='created_at' />
        <DateField label='Updated At' source='updated_at' />
      </SimpleShowLayout>
    </Show>
  );
};
