import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AddSourceButton from 'components/Buttons/AddSource';

import 'jspdf-autotable';

import { Pagination, useListContext } from 'react-admin';
import ExportDataButton from 'components/Buttons/ExportData';

const useStyles = makeStyles(() => ({
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    overflowY: 'hidden',
    marginTop: '13px',
  },
}));

export const Paginator = (props) => {
  const classes = useStyles();
  const { showExportButton, type, userID } = props;
  const { total } = useListContext();

  return (
    <div className={classes.paginationContainer}>
      {showExportButton && total !== 0 && <ExportDataButton type={type} />}

      {type === 'UserSources' && <AddSourceButton userID={userID} />}

      <Pagination
        style={{ marginLeft: 'auto', overflowY: 'hidden' }}
        rowsPerPageOptions={[15, 25, 50, 100, 500]}
        {...props}
      />
    </div>
  );
};
