import React from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { Resource } from 'react-admin';
import NotificationTypesViews from './NotificationType';
import clientActionViews from './ClientAction';
const NotificationsRoutes = [
  <Resource
    key='notifications'
    name='notifications'
    icon={NotificationsIcon}
    options={{ label: 'Notifications', isMenuParent: true }}
  />,
  <Resource key='notification-views' {...NotificationTypesViews} />,
  <Resource key='client-action-views' {...clientActionViews} />,
];

export default NotificationsRoutes;
