import React from 'react';
import { ViewTitle } from '../../../components/AppBar/Titles';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  FunctionField,
  ChipField,
} from 'react-admin';
import { AvatarContainer, UserAvatar } from 'components/Avatars';

export const ApplicationsShow = (props) => {
  return (
    <Show title={<ViewTitle source='name' />} {...props}>
      <SimpleShowLayout>
        <ReferenceField
          source='brand_id'
          reference='brands'
          label='Brand'
          link={(record, reference) => `/${reference}/${record.brand_id}/show`}
        >
          <FunctionField
            render={(record) => (
              <AvatarContainer>
                <UserAvatar url={record?.thumbnail_url || null} />
                {record.name}
              </AvatarContainer>
            )}
          />
        </ReferenceField>
        <ReferenceField
          source='expert_id'
          reference='experts'
          label='Expert'
          link={(record, reference) => `/${reference}/${record.expert_id}/show`}
        >
          <FunctionField
            render={(record) => (
              <AvatarContainer>
                <UserAvatar url={record?.thumbnail_url || null} />
                {record.nickname}
              </AvatarContainer>
            )}
          />
        </ReferenceField>

        <FunctionField
          sortBy='status'
          label='Status'
          render={(record) => {
            switch (record.status) {
              case 'approved':
                return (
                  <ChipField
                    record={record}
                    source='status'
                    variant='outlined'
                    style={{
                      textTransform: 'capitalize',
                      borderColor: '#44b700',
                    }}
                  />
                );

              case 'pending':
                return (
                  <ChipField
                    record={record}
                    source='status'
                    variant='outlined'
                    style={{
                      textTransform: 'capitalize',
                      borderColor: '#eead2d',
                    }}
                  />
                );

              case 'kyc declined':
                return (
                  <ChipField
                    record={record}
                    source='status'
                    variant='outlined'
                    style={{
                      textTransform: 'capitalize',
                      borderColor: '#e62424',
                    }}
                  />
                );

              case 'declined':
                return (
                  <ChipField
                    record={record}
                    source='status'
                    variant='outlined'
                    style={{ textTransform: 'capitalize' }}
                  />
                );

              default:
                return (
                  <ChipField
                    record={record}
                    source='status'
                    variant='outlined'
                    style={{
                      textTransform: 'capitalize',
                      borderColor: '#eead2d',
                    }}
                  />
                );
            }
          }}
        />
        <TextField source='about' label='About Expert' />
        <TextField source='reason' label='Reason for application' />
        <TextField source='timezone' label='Timezone' />
        <TextField source='discord' label='Discord' />
        <TextField source='discourse' label='Discource' />
        <TextField source='twitter' label='Twitter' />
        <TextField source='linkedin' label='LinkedIn' />
        <TextField source='github' label='GitHub' />
      </SimpleShowLayout>
    </Show>
  );
};
