import React from 'react';
import { EditTitle } from '../../../components/AppBar/Titles';
import { EditToolbar } from '../../../components/Toolbar/EditToolbar';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
} from 'react-admin';

export const SkillEdit = (props) => (
  <Edit title={<EditTitle source='name' />} {...props}>
    <SimpleForm variant='standard' margin='normal' toolbar={<EditToolbar />}>
      <TextInput source='name' validate={required()} fullWidth />
      <BooleanInput source='is_public' label='Is Public' fullWidth />
    </SimpleForm>
  </Edit>
);
