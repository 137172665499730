import DataGridList from 'components/containers/List';
import ListActions from 'components/ListAction/ListActions';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import React from 'react';
import { List, BooleanField, TextField } from 'react-admin';
import SettingsFilter from './filter';

export const SettingsList = (props) => (
  <List
    title='Settings'
    filters={<SettingsFilter />}
    pagination={<Paginator />}
    perPage={15}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
    empty={<EmptyPage create />}
    actions={<ListActions listExporterType='Settings' />}
  >
    <DataGridList show edit destroy>
      <TextField source='key' label='Key' />
      <TextField source='value' label='Value' />
      <BooleanField label='Client Accessible?' source='is_client_accessible' />
    </DataGridList>
  </List>
);
