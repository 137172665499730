import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import ExpertIcon from '@material-ui/icons/StarBorderRounded';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },

  avatar: {
    width: '30px',
    height: '30px',
  },
}));

const getBadgeColor = (status) => {
  switch (status) {
    case 'active':
      return '#44b700';
    case 'inactive':
      return '#e62424';
    default:
      return '#eead2d';
  }
};

export const ExpertAvatar = ({ url, status }) => {
  const classes = useStyles();
  const color = getBadgeColor(status);

  const StyledBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: color,
      color: color,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        content: '""',
      },
    },
  }))(Badge);

  return (
    <div className={classes.root}>
      <StyledBadge
        overlap='circular'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant='dot'
      >
        <Avatar alt='' src={url} className={classes.avatar}>
          <ExpertIcon />
        </Avatar>
      </StyledBadge>
    </div>
  );
};
