import React from 'react';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
} from 'react-admin';

export const SkillCreate = (props) => (
  <Create {...props} title='Create Skill'>
    <SimpleForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
      <TextInput source='name' validate={required()} fullWidth />
      <BooleanInput source='is_public' label='Is Public' fullWidth />
    </SimpleForm>
  </Create>
);
