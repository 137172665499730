import React from 'react';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';

const CountriesFilter = (props) => (
  <FilterContainer {...props} variant='standard' margin='normal'>
    <DebouncedTextField label='Name' source='name' />
    <DebouncedTextField label='Code' source='code' />
    <DebouncedTextField label='ISO' source='iso' />
  </FilterContainer>
);

export default CountriesFilter;
