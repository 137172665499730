import { Box, IconButton } from '@material-ui/core';
import BoldText from 'components/common/BoldText';
import OpenIcon from '@material-ui/icons/OpenInNew';
import CopyToClipboardButton from 'components/common/CopyToClipboard';

const AddressSection = (props) => {
  const { title, address, blockExplorerUrl } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {title}:&nbsp;&nbsp;
      <BoldText>{address}</BoldText>
      <Box sx={{ marginLeft: '10px', minWidth: '90px' }}>
        <CopyToClipboardButton
          textToCopy={address}
          successMessage='Address copied to clipboard'
        />
        <IconButton
          target='_blank'
          href={`${blockExplorerUrl}/address/${address}`}
        >
          <OpenIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default AddressSection;
