import React, { useEffect, useState } from 'react';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';
import {
  Create,
  SimpleForm,
  required,
  ReferenceInput,
  AutocompleteInput,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { useForm, useFormState } from 'react-final-form';

const getExpertLabel = (expert) => {
  if (!expert) return '';
  if (expert.nickname) return expert.nickname;

  return expert.public_id
    ? `Wallet: ${expert.public_id}`
    : `UUID: (${expert.uuid})`;
};

const TagInput = () => {
  const notify = useNotify();
  const form = useForm();
  const { values } = useFormState();
  const dataProvider = useDataProvider();

  const [loading, setLoading] = useState(true);
  const [brandUUID, setBrandUUID] = useState(null);

  /**
   * Update tags list when expert is changed
   */

  useEffect(() => {
    if (!values['expert_uuid']) return;

    dataProvider
      .getOne('experts', { id: values['expert_uuid'] })
      .then(({ data }) => {
        // Set brand UUID
        setBrandUUID(data['brand_uuid']);

        // Clear tag field
        form.change('tag_uuid', undefined);

        setLoading(false);
      })
      .catch(() => {
        notify(`Error retrieving expert's brand tags`, { type: 'error' });
        setLoading(false);
      });
  }, [values['expert_uuid']]);

  return (
    <ReferenceInput
      loading={loading}
      filter={{ owner_uuid: brandUUID }}
      validate={required()}
      disabled={loading || !values['expert_uuid']}
      source='tag_uuid'
      reference='tags'
      label='Tag'
      perPage={25}
      filterToQuery={(searchText) => ({
        id: parseInt(searchText),
        name: searchText,
        _autocomplete: undefined,
      })}
      sort={{ field: 'id', order: 'ASC' }}
      fullWidth
    >
      <AutocompleteInput
        optionValue='uuid'
        matchSuggestion={() => true}
        optionText={(choice) => choice?.name}
      />
    </ReferenceInput>
  );
};

export const ExpertTagsCreate = (props) => {
  return (
    <Create {...props} title='Create Expert Tag'>
      <SimpleForm
        variant='standard'
        margin='normal'
        toolbar={<CreateToolbar />}
      >
        <ReferenceInput
          validate={required()}
          source='expert_uuid'
          reference='experts'
          label='Expert'
          perPage={25}
          filterToQuery={(searchText) => ({
            id: parseInt(searchText),
            nickname: searchText,
            email: '@',
          })}
          sort={{ field: 'id', order: 'DESC' }}
          fullWidth
        >
          <AutocompleteInput
            optionValue='uuid'
            matchSuggestion={() => true}
            optionText={(choice) => getExpertLabel(choice)}
          />
        </ReferenceInput>
        <TagInput />
      </SimpleForm>
    </Create>
  );
};
