import FilterContainer from 'components/containers/Filter';
import React from 'react';
import { SelectInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { DebouncedTextField } from 'components/Fields';
import {
  EmailField,
  PhoneField,
} from '../../../components/Fields/DebouncedFields/DebounceField/DebounceField';

const RepresentativesFilter = (props) => (
  <FilterContainer {...props} variant='standard' margin='normal'>
    <DebouncedTextField label='ID' source='id' />

    <DebouncedTextField label='First Name' source='first_name' />

    <DebouncedTextField label='Last Name' source='last_name' />

    <DebouncedTextField label='Name' source='name' />

    <ReferenceInput
      source='brand_id'
      reference='brands'
      label='Brand'
      perPage={25}
      filterToQuery={(searchText) => ({
        id: parseInt(searchText),
        first_name: searchText,
        last_name: searchText,
        name: searchText,
        _autocomplete: true,
      })}
    >
      <AutocompleteInput
        matchSuggestion={() => true}
        optionText={(choice) => {
          return choice && `${choice.name} (${choice.id})`;
        }}
      />
    </ReferenceInput>

    <EmailField source='email' label='Email' />

    <SelectInput
      source='status'
      label='Status'
      choices={[
        { id: 'pending', name: 'Pending' },
        { id: 'active', name: 'Active' },
        { id: 'inactive', name: 'Inactive' },
        { id: 'suspended', name: 'Suspended' },
      ]}
    />
    <SelectInput
      source='type'
      label='Type'
      choices={[
        { id: 'root', name: 'Root' },
        { id: 'admin', name: 'Admin' },
      ]}
    />
    <PhoneField label='Mobile Number' source='phone_number' />
  </FilterContainer>
);

export default RepresentativesFilter;
