import { ChipField } from 'react-admin';

/**
 * Components to make status column less verbose on all the list components
 */
const StatusChip = ({ record, borderColor, source = 'status' }) => {
  return (
    <ChipField
      record={record}
      source={source}
      variant='outlined'
      style={{
        textTransform: 'capitalize',
        borderColor: borderColor,
      }}
    />
  );
};

export default StatusChip;
