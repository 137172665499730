import SkillsIcon from '@material-ui/icons/FitnessCenterRounded';

import { SkillEdit } from './edit';
import { SkillShow } from './show';
import { SkillsList } from './list';
import { SkillCreate } from './create';

export default {
  name: 'skills',
  options: { label: 'Skills', menuParent: 'expert' },
  icon: SkillsIcon,
  list: SkillsList,
  show: SkillShow,
  create: SkillCreate,
  edit: SkillEdit,
};
