import DataGridList from 'components/containers/List';
import ListActions from 'components/ListAction/ListActions';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import React from 'react';
import { List, TextField, BooleanField } from 'react-admin';
import SkillFilters from './filter';
// import TimeZonesFilter from "./filter";

export const SkillsList = (props) => (
  <List
    title='Skills'
    filters={<SkillFilters />}
    pagination={<Paginator />}
    perPage={15}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
    empty={<EmptyPage create />}
    actions={<ListActions listExporterType='Skills' />}
  >
    <DataGridList show edit>
      <TextField source='name' label='Name' />
      <BooleanField label='Is Public' source='is_public' />
    </DataGridList>
  </List>
);
