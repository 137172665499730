import { FileUploaderPlaceholder } from 'components/ImageContainer/FilePlaceholder/FileUploaderPlaceholder';
import { useState } from 'react';
import { FileInput, useNotify, useRecordContext } from 'react-admin';
import { ContainedImage } from './Image';
import { Box } from '@material-ui/core';

export const DEFAULT_MAX_IMG_SIZE_BYTES = 2000000;

/**
 * Enables image upload and shows current stored image
 */
export const ImageUploaderWithPreview = ({
  source,
  maxSize = DEFAULT_MAX_IMG_SIZE_BYTES,
  fullWidth = true,
  ...rest
}) => {
  const [showPreview, setShowPreview] = useState(true);
  const record = useRecordContext();
  const notify = useNotify();

  const handleReject = (file) => {
    if (file.size > maxSize) {
      notify(
        `Uploaded file exceeds the maximum size of ${
          maxSize / 1000000
        }MB. File size: ${file.size / 1000000}MB`,
        { type: 'info' },
      );
    }
  };

  return (
    <Box
      style={{
        marginBottom: '30px',
      }}
    >
      <FileInput
        source={`${source}_file`}
        fullWidth={fullWidth}
        accept='image/*'
        label='Thumbnail'
        placeholder={<FileUploaderPlaceholder maxSize={maxSize} />}
        {...rest}
        options={{
          onDropAccepted: () => setShowPreview(false),
          onRemove: () => setShowPreview(true),
          onDropRejected: (files) => handleReject(files[0]),
        }}
        maxSize={maxSize}
      >
        <ContainedImage source='src' />
      </FileInput>
      {showPreview && source && record[`${source}_url`] && (
        <img
          style={{
            maxHeight: '10rem',
            maxWidth: '100%',
          }}
          alt={source}
          src={record[`${source}_url`]}
        />
      )}
    </Box>
  );
};
