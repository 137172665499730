import React from 'react';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';
import { Create, SimpleForm, TextInput, required } from 'react-admin';

export const StatesCreate = (props) => (
  <Create {...props} title='Create State'>
    <SimpleForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
      <TextInput source='iso' validate={required()} fullWidth />
      <TextInput source='name' validate={required()} fullWidth />
    </SimpleForm>
  </Create>
);
