import DataGridList from 'components/containers/List';
import ListActions from 'components/ListAction/ListActions';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import React from 'react';
import {
  List,
  TextField,
  ReferenceField,
  FunctionField,
  SingleFieldList,
  ChipField,
  ArrayField,
} from 'react-admin';
import { AvatarContainer, UserAvatar } from 'components/Avatars';
import StatusChip from 'components/common/StatusChip';
import ConversationsFilter from './filter';
import { TICKET_STATUS } from './constants';
import ActiveExpert from './components/ActiveExpert';
import { getEmailOrWallet } from 'utils/users';

export const ConversationsList = (props) => (
  <List
    title='Conversations'
    filters={<ConversationsFilter />}
    pagination={<Paginator />}
    perPage={25}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
    empty={<EmptyPage create />}
    actions={<ListActions hideCreateButton='true' hideExportButton='true' />}
  >
    <DataGridList show>
      <ReferenceField
        source='brand_id'
        reference='brands'
        label='Brand'
        link={(record, reference) => `/${reference}/${record.brand_id}/show`}
      >
        <FunctionField
          render={(record) => (
            <AvatarContainer>
              <UserAvatar url={record?.thumbnail_url || null} />
              {record.name}
            </AvatarContainer>
          )}
        />
      </ReferenceField>
      <FunctionField
        label='Expert'
        render={(record) => <ActiveExpert record={record} />}
      />
      <ReferenceField
        source='user_id'
        reference='users'
        label='User'
        link={(record, reference) => `/${reference}/${record.user_id}/show`}
      >
        <FunctionField
          render={(record) =>
            `${getEmailOrWallet(record, true)} (${record.id})`
          }
        />
      </ReferenceField>
      <ReferenceField
        label='Season'
        source='season_id'
        reference='seasons'
        link={false}
      >
        <TextField source='number' />
      </ReferenceField>

      <ArrayField source='tags'>
        <SingleFieldList>
          <ChipField source='name' />
        </SingleFieldList>
      </ArrayField>

      <FunctionField
        sortBy='status'
        label='Status'
        render={(record) => {
          switch (record.status) {
            case TICKET_STATUS.FINALIZED:
            case TICKET_STATUS.SOLVED:
              return <StatusChip record={record} borderColor='#44b700' />;

            case TICKET_STATUS.PENDING_DISPUTE:
              return <StatusChip record={record} borderColor='#e62424' />;

            default:
              return <StatusChip record={record} borderColor='#eead2d' />;
          }
        }}
      />
      <TextField source='number_of_requeues' label='Requeues' />
    </DataGridList>
  </List>
);
