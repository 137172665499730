import settingsIcon from '@material-ui/icons/SettingsApplications';

import { SettingsList } from './list';
import { SettingsShow } from './show';
import { SettingsCreate } from './create';
import { SettingsEdit } from './edit';

export default {
  name: 'settings',
  options: { label: 'Settings', menuParent: 'system' },
  icon: settingsIcon,
  list: SettingsList,
  show: SettingsShow,
  create: SettingsCreate,
  edit: SettingsEdit,
};
