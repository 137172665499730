import { FileUploaderPlaceholder } from 'components/ImageContainer/FilePlaceholder/FileUploaderPlaceholder';
import { CreateToolbar } from 'components/Toolbar/CreateToolbar';
import React from 'react';
import {
  Create,
  TextInput,
  FileInput,
  ImageField,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  TabbedForm,
  FormTab,
  required,
  email,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

export const RepresentativesCreate = (props) => (
  <Create {...props} title='Create Admin User'>
    <TabbedForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
      <FormTab label='Personal Details'>
        <ReferenceInput
          source='brand_id'
          reference='brands'
          label='Brand'
          perPage={25}
          filterToQuery={(searchText) => ({
            id: parseInt(searchText),
            first_name: searchText,
            last_name: searchText,
            name: searchText,
            _autocomplete: true,
          })}
          fullWidth
        >
          <AutocompleteInput
            matchSuggestion={() => true}
            optionText={(choice) => {
              return choice && `${choice.name} (${choice.id})`;
            }}
          />
        </ReferenceInput>
        <TextInput
          source='first_name'
          label='First Name'
          validate={required()}
          fullWidth
        />
        <TextInput
          source='last_name'
          label='Last Name'
          validate={required()}
          fullWidth
        />
        <TextInput
          source='email'
          label='Email'
          validate={[email(), required()]}
          fullWidth
        />
        <SelectInput
          label='Type'
          source='type'
          validate={required()}
          fullWidth
          choices={[
            { id: 'admin', name: 'Admin' },
            { id: 'root', name: 'Root' },
          ]}
        />
        <SelectInput
          source='status'
          validate={required()}
          fullWidth
          choices={[
            { id: 'pending', name: 'Pending' },
            { id: 'active', name: 'Active' },
            { id: 'inactive', name: 'Inactive' },
            { id: 'suspended', name: 'Suspended' },
          ]}
        />
        <FileInput
          source='thumbnail_file'
          accept='image/*'
          label='Thumbnail'
          placeholder={<FileUploaderPlaceholder />}
          fullWidth
        >
          <ImageField source='src' />
        </FileInput>
      </FormTab>

      <FormTab label='Email & Password'>
        <ArrayInput label='' source='email_password_credentials'>
          <SimpleFormIterator disableRemove disableReordering>
            <TextInput
              label='Email'
              source='sid'
              validate={[email(), required()]}
              fullWidth
            />
            <TextInput
              label='Password'
              type='password'
              source='secret'
              fullWidth
            />
            <SelectInput
              label='Status'
              source='status'
              validate={required()}
              fullWidth
              choices={[
                { id: 'pending', name: 'Pending Confirmation' },
                { id: 'active', name: 'Active' },
                { id: 'locked', name: 'Locked' },
                { id: 'suspended', name: 'Suspended' },
              ]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
