import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { useInput } from 'react-admin';

const filter = createFilterOptions();

export const DynamicAutocompleteInput = (props) => {
  const { label, choices, setFilter } = props;
  const {
    input: { name, onChange, value },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  const [_value, setValue] = React.useState(value);

  return (
    <Autocomplete
      value={_value}
      onChange={(event, newValue) => {
        let v = null;

        if (typeof newValue === 'string') {
          // Value selected with enter, right from the input
          v = newValue;
        } else if (newValue?.inputValue) {
          // Option created dynamically
          v = newValue.inputValue;
        } else if (newValue) {
          // Regular option
          v = newValue.name;
        }

        setValue(v);
        onChange(v);
      }}
      options={choices}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            name: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }

        // Option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }

        // Regular option
        return option.name;
      }}
      renderOption={(option) => option.name}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          label={label}
          onChange={(event) => setFilter(event.target.value)}
          error={!!(touched && error)}
          helperText={touched && error}
          required={isRequired}
        />
      )}
    />
  );
};
