import { ViewActions } from 'components/Action/ViewActions';
import { ViewTitle } from 'components/AppBar/Titles';
import { DateFieldShow } from 'components/Fields';
import React from 'react';
import {
  SimpleShowLayout,
  Datagrid,
  ImageField,
  ReferenceField,
  ReferenceArrayField,
  ChipField,
  Show,
  Tab,
  SingleFieldList,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

export const UsersShow = (props) => {
  // in order to add a current source icon out of all of the
  // sources we're presenting to the user, we're creating validation using
  // current_source from the original user record:

  return (
    <Show
      actions={<ViewActions />}
      title={<ViewTitle source='email' />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label='Personal Info'>
          <TextField source='id' label='ID' />
          <TextField source='nickname' label='Display Name' />
          <ImageField source='thumbnail_url' label='Thumbnail' />

          <TextField source='email' label='Contact Email' />
          <TextField source='phone_number' label='Contact Phone' />

          <ReferenceField
            label='Primary language'
            source='language_id'
            reference='languages'
          >
            <TextField source='name' />
          </ReferenceField>

          <ReferenceArrayField source='languages_ids' reference='languages'>
            <SingleFieldList>
              <ChipField source='name' />
            </SingleFieldList>
          </ReferenceArrayField>

          <DateFieldShow label='Created At' source='created_at' />
          <DateFieldShow label='Updated At' source='updated_at' />
        </Tab>

        <Tab label='Address'>
          <ReferenceField
            source='country_id'
            reference='countries'
            label='Country'
          >
            <TextField source='name' />
          </ReferenceField>
          <ReferenceField source='state_id' reference='states' label='State'>
            <TextField source='name' />
          </ReferenceField>
          <ReferenceField source='city_id' reference='cities' label='City'>
            <TextField source='name' />
          </ReferenceField>
          <TextField source='address' />
          <TextField source='zip_code' label='Zip Code' fullWidth />
          <ReferenceField
            source='time_zone_id'
            reference='time_zones'
            label='Time zone'
          >
            <TextField source='name' />
          </ReferenceField>
        </Tab>

        <Tab label='Reviews'>
          <SimpleShowLayout>
            <ReferenceArrayField
              source='reviews_ids'
              reference='reviews'
              fullWidth
            >
              <Datagrid>
                <ReferenceField
                  source='reviewable_id'
                  reference='experts'
                  label='Reviewable Name'
                >
                  <TextField source='name' />
                </ReferenceField>
                <TextField source='comment' />
                <TextField source='rating' />
                <TextField source='status' />
              </Datagrid>
            </ReferenceArrayField>
          </SimpleShowLayout>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
