import React, { useContext } from 'react';
import { LoginForm } from './LoginForm';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeContext } from '../../themes/ThemeContext';
import { useHistory } from 'react-router-dom';
import { VerificationForm } from './VerificationForm';
import SherpaLogo from '../../assets/images/Logo/logo.svg';
import {
  useAuthProvider,
  useNotify,
  useSafeSetState,
  Login,
} from 'react-admin';

const LiveExpertsLogin = (props) => {
  const { theme } = useContext(ThemeContext); // eslint-disable-line

  const pageStyles = makeStyles({
    main: {
      background: 'none',
      backgroundColor: theme === 'dark' ? '#3f3f3fe6' : '#248ad3',
    },
    card: {
      padding: '30px',
      backgroundImage: `url(${SherpaLogo})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '150px',
      backgroundPosition: 'center 40px',
      marginBottom: '30px',
    },
    avatar: {
      margin: '3.5em',
    },
    icon: {
      display: 'none',
    },
  });

  const history = useHistory();
  const [step, setStep] = useSafeSetState('login');
  const [loading, setLoading] = useSafeSetState(false);

  const notify = useNotify();
  const authProvider = useAuthProvider();

  const pageClasses = pageStyles();
  const pageProps = {
    ...props,
    classes: pageClasses,
  };

  const handleLogin = (values) => {
    setLoading(true);
    authProvider
      .login(values)
      .then(() => {
        setLoading(false);
        setStep('verify');
      })
      .catch((error) => {
        const message =
          typeof error === 'string'
            ? error
            : error.message || 'ra.auth.sign_in_error';
        setLoading(false);
        notify(message, 'warning');
      });
  };

  const handleVerification = (values) => {
    setLoading(true);
    authProvider
      .verify(values)
      .then(() => {
        setLoading(false);
        history.push('/');
      })
      .catch((error) => {
        const message =
          typeof error === 'string'
            ? error
            : error.message || 'ra.auth.sign_in_error';
        setLoading(false);
        notify(message, 'warning');
      });
  };

  return (
    <Login {...pageProps}>
      {step === 'login' ? (
        <LoginForm onSubmit={handleLogin} loading={loading} />
      ) : (
        <VerificationForm onSubmit={handleVerification} loading={loading} />
      )}
    </Login>
  );
};

export default LiveExpertsLogin;
