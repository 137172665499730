import triggersIcon from '@material-ui/icons/AdjustRounded';
import { TriggersList } from './list';
import { TriggersCreate } from './create';

export default {
  name: 'triggers',
  options: { label: 'Triggers', menuParent: 'system' },
  icon: triggersIcon,
  list: TriggersList,
  create: TriggersCreate,
};
