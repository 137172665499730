import AdminUsersIcon from '@material-ui/icons/SupervisedUserCircleRounded';
import { AdminUsersCreate } from './create';
import { AdminUsersEdit } from './edit';
import { AdminUsersList } from './list';
import { AdminUsersShow } from './show';

export default {
  name: 'admin_users',
  options: { label: 'Accounts', menuParent: 'system' },
  icon: AdminUsersIcon,
  list: AdminUsersList,
  show: AdminUsersShow,
  create: AdminUsersCreate,
  edit: AdminUsersEdit,
};
