import TimeZonesIcon from '@material-ui/icons/SnoozeRounded';
import { TimeZonesList } from './list';
import { TimeZonesShow } from './show';
import { TimeZonesCreate } from './create';
import { TimeZonesEdit } from './edit';
export default {
  name: 'time_zones',
  options: { label: 'Time Zones', menuParent: 'localization' },
  icon: TimeZonesIcon,
  list: TimeZonesList,
  show: TimeZonesShow,
  create: TimeZonesCreate,
  edit: TimeZonesEdit,
};
