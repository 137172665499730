import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import UploadIcon from '@material-ui/icons/BackupOutlined';
import { DEFAULT_MAX_IMG_SIZE_BYTES } from 'components/common/ImageUploaderWithPreview';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '15px',
  },
  icon: {
    width: '40px',
    height: '40px',
    color: `${theme.palette.type === 'dark' ? '#248ad3' : '#41cea5'}`,
  },
  title: {
    marginTop: '0',
    fontWeight: 'bold',
    fontSize: '25px',
  },
  or: {
    marginBottom: '15px',
    fontSize: '13px',
  },
  hint: {
    marginTop: '15px',
    fontSize: '13px',
  },
}));

export const FileUploaderPlaceholder = ({
  type,
  maxSize = DEFAULT_MAX_IMG_SIZE_BYTES,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <UploadIcon className={classes.icon} />
      <h1 className={classes.title}>Drag & Drop Files Here</h1>
      <div className={classes.or}>or</div>

      <Button
        variant='outlined'
        color='primary'
        startIcon={<SaveIcon />} // eslint-disable-line
        disableElevation
      >
        Browse Files
      </Button>

      <div className={classes.hint}>
        {`Max upload size: ${maxSize / 1000000}mb`}
        <br></br>
        <br></br>
        {type === 'email_attachment' && (
          <div style={{ color: 'yellow' }}>(PDF and image files only)</div>
        )}
        {type === 'gallery' && (
          <div style={{ color: 'yellow' }}>
            (Can only have 1 or picture that multiply by 5 (1,5,10,15,....))
          </div>
        )}
      </div>
    </div>
  );
};
