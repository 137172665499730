import React from 'react';
import { ViewTitle } from '../../../components/AppBar/Titles';
import { Show, SimpleShowLayout, TextField, ChipField } from 'react-admin';
import { DateFieldShow } from 'components/Fields';

export const TimeZonesShow = (props) => (
  <Show title={<ViewTitle source='name' />} {...props}>
    <SimpleShowLayout>
      <TextField source='id' label='ID' />
      <ChipField source='iso' />
      <TextField source='name' />
      <DateFieldShow label='Created At' source='created_at' />
      <DateFieldShow label='Updated At' source='updated_at' />
    </SimpleShowLayout>
  </Show>
);
