import React from 'react';
import { ReferenceInput, AutocompleteInput, TextInput } from 'react-admin';
import FilterContainer from 'components/containers/Filter';

export const TriggersFilter = (props) => (
  <FilterContainer {...props} variant='standard' margin='normal'>
    <ReferenceInput
      source='owner_id'
      reference='users'
      label='Owner'
      perPage={10}
      filterToQuery={(searchText) => ({
        id: parseInt(searchText),
        first_name: searchText,
        last_name: searchText,
        name: searchText,
        _autocomplete: true,
      })}
      sort={{ field: 'id', order: 'ASC' }}
      fullWidth
    >
      <AutocompleteInput
        matchSuggestion={() => true}
        optionText={(choice) =>
          choice?.id ? `${choice.name} (${choice.id})` : 'None'
        }
      />
    </ReferenceInput>
    <TextInput label='Type' source='type' />
  </FilterContainer>
);

export default TriggersFilter;
