import React from 'react';
import { SelectInput } from 'react-admin';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';

const SkillFilter = (props) => (
  <FilterContainer {...props} variant='standard' margin='normal'>
    <DebouncedTextField label='Name' source='name' />
    <SelectInput
      source='is_public'
      label='Is Public'
      choices={[
        { id: null, name: 'Unknown' },
        { id: true, name: 'Yes' },
        { id: false, name: 'No' },
      ]}
    />
  </FilterContainer>
);

export default SkillFilter;
