import React from 'react';
import AdminUsersFilter from './filter';
import {
  List,
  FunctionField,
  ArrayField,
  SingleFieldList,
  EmailField,
} from 'react-admin';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';
import EmptyPage from 'components/EmptyPage';
import { AvatarContainer, UserAvatar } from 'components/Avatars';
import DataGridList from 'components/containers/List';

export const AdminUsersList = (props) => (
  <List
    title='Accounts'
    filters={<AdminUsersFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    pagination={<Paginator />}
    perPage={25}
    {...props}
    empty={<EmptyPage create />}
    actions={<ListActions listExporterType='AdminUsers' />}
  >
    <DataGridList edit show destroy>
      <FunctionField
        sortBy='first_name'
        label='Name'
        render={(record) => (
          <AvatarContainer>
            <UserAvatar url={record?.thumbnail_url || null} />
            {record.first_name} {record.last_name}
          </AvatarContainer>
        )}
      />

      <ArrayField
        sortable={false}
        source='email_password_credentials'
        label='Email(s)'
      >
        <SingleFieldList>
          <EmailField source='sid' />
        </SingleFieldList>
      </ArrayField>
    </DataGridList>
  </List>
);
