import DataGridList from 'components/containers/List';
import ListActions from 'components/ListAction/ListActions';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import React from 'react';
import { List, ReferenceField, FunctionField } from 'react-admin';
import ExpertTagsFilter from './filter';
import { AvatarContainer, UserAvatar } from 'components/Avatars';

export const ExpertTagsList = (props) => (
  <List
    title='Tags'
    filters={<ExpertTagsFilter />}
    pagination={<Paginator />}
    perPage={25}
    sort={{ field: 'created_at', order: 'DESC' }}
    {...props}
    empty={<EmptyPage create />}
    actions={<ListActions hideExportButton={true} />}
  >
    <DataGridList show destroy showId={false}>
      <ReferenceField
        source='expert_uuid'
        reference='experts'
        label='Expert'
        link={(record, reference) => `/${reference}/${record.expert_uuid}/show`}
      >
        <FunctionField
          render={(record) => (
            <AvatarContainer>
              <UserAvatar url={record?.thumbnail_url || null} />
              {record.nickname}
            </AvatarContainer>
          )}
        />
      </ReferenceField>
      <ReferenceField
        source='brand_uuid'
        reference='brands'
        label='Brand'
        link={(record, reference) => `/${reference}/${record.brand_uuid}/show`}
      >
        <FunctionField
          render={(record) => (
            <AvatarContainer>
              <UserAvatar url={record?.thumbnail_url || null} />
              {record.name}
            </AvatarContainer>
          )}
        />
      </ReferenceField>

      <ReferenceField
        source='tag_uuid'
        reference='tags'
        label='Tag'
        link={(record, reference) => `/${reference}/${record.tag_uuid}/show`}
      >
        <FunctionField render={(record) => <>{record.name}</>} />
      </ReferenceField>
    </DataGridList>
  </List>
);
