import ExpertsIcon from '@material-ui/icons/AppsRounded';

import { ExpertList } from './list';
import { ExpertsShow } from './show';
import { ExpertsEdit } from './edit';
import { ExpertsCreate } from './create';

export default {
  key: 'experts',
  name: 'experts',
  options: { label: 'Main', menuParent: 'expert' },
  icon: ExpertsIcon,
  list: ExpertList,
  show: ExpertsShow,
  create: ExpertsCreate,
  edit: ExpertsEdit,
};
