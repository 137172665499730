import moment from 'moment';

/**
 * Format date based on unit
 */
export const formatDateByUnit = (dateToFormat, unit) => {
  const date = moment(dateToFormat);
  let formattedDate;

  switch (unit) {
    case 'year':
    case 'years':
      formattedDate = date.format('YYYY');
      break;
    case 'month':
    case 'months':
      formattedDate = date.format('MMMM YYYY');
      break;
    case 'week':
    case 'weeks':
    case 'day':
    case 'days':
      formattedDate = date.format('D MMMM YYYY');
      break;
    case 'hour':
    case 'hours':
    case 'minute':
    case 'minutes':
      formattedDate = date.format('hh:mm D MMM YYYY');
      break;
    case 'second':
    case 'seconds':
      formattedDate = date.format('hh:mm:ss D MMM YYYY');
      break;
    default:
      break;
  }

  return formattedDate;
};
