import { EditTitle } from 'components/AppBar/Titles';
import { FileUploaderPlaceholder } from 'components/ImageContainer/FilePlaceholder/FileUploaderPlaceholder';
import { EditToolbar } from 'components/Toolbar/EditToolbar';
import React from 'react';
import {
  Edit,
  TextInput,
  FileInput,
  ImageField,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  TabbedForm,
  FormTab,
  required,
  email,
} from 'react-admin';

export const RepresentativesEdit = (props) => (
  <Edit title={<EditTitle source='email' />} {...props}>
    <TabbedForm variant='standard' margin='normal' toolbar={<EditToolbar />}>
      <FormTab label='Personal Details'>
        <TextInput
          source='first_name'
          label='First Name'
          validate={required()}
          fullWidth
        />
        <TextInput
          source='last_name'
          label='Last Name'
          validate={required()}
          fullWidth
        />
        <TextInput
          source='email'
          label='Email'
          validate={[email(), required()]}
          disabled
          fullWidth
        />
        <SelectInput
          label='Type'
          source='type'
          validate={required()}
          fullWidth
          choices={[
            { id: 'admin', name: 'Admin' },
            { id: 'root', name: 'Root' },
          ]}
        />
        <FileInput
          source='thumbnail_file'
          accept='image/*'
          label='Thumbnail'
          placeholder={<FileUploaderPlaceholder />}
          fullWidth
        >
          <ImageField source='src' />
        </FileInput>
      </FormTab>

      <FormTab label='Email & Password'>
        <ArrayInput label='' source='email_password_credentials'>
          <SimpleFormIterator disableRemove disableReordering>
            <TextInput
              label='Email'
              source='sid'
              validate={[email(), required()]}
              fullWidth
            />
            <TextInput
              label='Password'
              type='password'
              source='secret'
              fullWidth
            />
            <SelectInput
              label='Status'
              source='status'
              validate={required()}
              fullWidth
              choices={[
                { id: 'pending', name: 'Pending Confirmation' },
                { id: 'active', name: 'Active' },
                { id: 'locked', name: 'Locked' },
                { id: 'suspended', name: 'Suspended' },
              ]}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);
