import Button from '../Button';
import config from '../../../config';
import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: 700,
  },
}));

const generateTempCredentials = async (resource, id) => {
  const token = localStorage.getItem('token');
  return await fetch(
    `${config.API_URL}/resources/${resource}/${id}/credentials/temp`,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'X-Auth-Token': token,
      }),
    },
  ).then((res) => res.json());
};

const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);

  const toggle = useCallback(() => setState((state) => !state), []);

  return [state, toggle];
};

const GenerateTempCredentials = ({ resource, record }) => {
  const [open, toggle] = useToggle();
  const [data, setData] = useState({});
  const classes = useStyles();
  const handleActive = async () => {
    await generateTempCredentials(resource, record.id)
      .then(({ data }) => {
        setData({ ...data });
      })
      .finally(() => toggle());
  };
  return (
    <>
      <Button label='Access' onClick={handleActive}>
        <LockIcon />
      </Button>
      <Dialog onClose={toggle} open={open} classes={{ paper: classes.paper }}>
        <DialogTitle>Temp access credentials for {record.name}</DialogTitle>
        <DialogContent>
          <div style={{ margin: '30px 0' }}>
            <b>Email</b>
            <br /> {data.email}
          </div>
          <div style={{ margin: '30px 0' }}>
            <b>Password</b>
            <br /> {data.password}
          </div>
          <div style={{ margin: '30px 0' }}>
            <b>Note: the above credentials will expire in 5 minutes</b>
          </div>
        </DialogContent>
        <DialogActions>
          <Button label='Ok' onClick={toggle} fullWidth />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GenerateTempCredentials;
