/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { httpClient } from 'providers/Data';
import config from 'config';
import { useUpdateLoading } from 'ra-core';

const useMetadata = () => {
  const [metadata, setMetadata] = useState(undefined);
  const { startLoading, stopLoading } = useUpdateLoading();

  useEffect(() => {
    async function fetchMetadata() {
      startLoading();
      try {
        const { json } = await httpClient(`${config.API_URL}/utils/meta`);
        setMetadata(json.data);
      } catch (e) {
        console.error(e);
      }
      stopLoading();
    }
    fetchMetadata().catch((error) =>
      console.error('Error fetching the user metadata', error),
    );
  }, []);

  return metadata;
};

export default useMetadata;
