import * as React from 'react';
import { Box, Card, CardActions, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import publishArticleImage from '../../assets/images/Dashboard/dashboardWelcomeImage.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.type === 'dark' ? '#535353' : '#e9e9e9',
    color: '#fff',
    padding: 20,
    marginTop: theme.spacing(2),
    height: '8.5rem',
  },
  media: {
    background: `url(${publishArticleImage}) top right / cover`,
    marginLeft: 'auto',
  },
  actions: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
      flexWrap: 'wrap',
      '& a': {
        marginTop: '1em',
        marginLeft: '0!important',
        marginRight: '1em',
      },
    },
  },

  actionButton: {
    background: theme.palette.type === 'light' && '#fff',
    '&:hover': {
      background: theme.palette.type === 'light' && '#e9e9e9',
    },
  },

  header: {
    color: theme.palette.type === 'light' && 'black',
  },

  subHeader: {
    color: theme.palette.type === 'light' && 'black',
  },
}));

const Welcome = () => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Box display='flex'>
        <Box flex='1'>
          <Typography
            className={classes.header}
            variant='h5'
            component='h2'
            gutterBottom
          >
            Welcome to VillageDAO&apos;s Admin Console
          </Typography>
          <Box mb={3} maxWidth='40em'>
            <Typography
              className={classes.subHeader}
              variant='body1'
              component='p'
            >
              You can easely access resources using the dashboard&apos;s quick
              links.
            </Typography>
          </Box>
          <CardActions className={classes.actions}>
            {/* <Button
							variant="contained"
							className={classes.actionButton}
							color="secondary"
							href="/#/revenue_sharing"
							startIcon={<ReportIcon />} // eslint-disable-line
						>
							<span style={{ marginTop: "3px" }}>Rev. Share Report</span>
						</Button>
						<Button
							variant="contained"
							className={classes.actionButton}
							color="secondary"
							href="/#/MAU"
							startIcon={<ReportIcon />} // eslint-disable-line
						>
							<span style={{ marginTop: "3px" }}>MAU Report</span>
						</Button> */}
          </CardActions>
        </Box>

        <Box
          display={{ xs: 'none', sm: 'none', md: 'block' }}
          className={classes.media}
          width='16em'
          height='9em'
          overflow='hidden'
        />
      </Box>
    </Card>
  );
};

export default Welcome;
