import { Box } from '@material-ui/core';

const RoundedBorderSection = (props) => {
  const { color } = props;

  return (
    <Box
      sx={{
        border: `1px solid ${color}`,
        borderRadius: 12,
        padding: 20,
        maxWidth: '860px',
      }}
    >
      {props.children}
    </Box>
  );
};

export default RoundedBorderSection;
