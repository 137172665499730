import React from 'react';
import {
  ChipField,
  BooleanField,
  List,
  TextField,
  Datagrid,
  EditButton,
} from 'react-admin';

import { DateField } from 'components/Fields';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import ListActions from 'components/ListAction/ListActions';
import { Paginator } from 'components/Paginator/Paginator';
import CountriesFilter from './filter';

export const CountriesList = (props) => (
  <List
    title='Countries'
    filters={<CountriesFilter />}
    pagination={<Paginator />}
    perPage={15}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
    empty={<EmptyPage create />}
    actions={<ListActions listExporterType='Countries' />}
  >
    <Datagrid>
      <TextField source='id' label='ID' />
      <TextField source='name' label='Name' />
      <ChipField source='iso' label='ISO' />
      <BooleanField source='is_blocked' />
      <TextField source='code' label='Code' />
      <DateField label='Created At' source='created_at' />
      <EditButton />
    </Datagrid>
  </List>
);
