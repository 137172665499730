import CountriesIcon from '@material-ui/icons/FlagRounded';
import { CountriesList } from './list';
import { CountriesShow } from './show';
import { CountriesCreate } from './create';
import { CountriesEdit } from './edit';

export default {
  name: 'countries',
  options: { label: 'Countries', menuParent: 'geo' },
  icon: CountriesIcon,
  list: CountriesList,
  show: CountriesShow,
  create: CountriesCreate,
  edit: CountriesEdit,
};
