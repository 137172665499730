import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  FunctionField,
} from 'react-admin';
import { ViewTitle } from '../../../components/AppBar/Titles';
import { AvatarContainer, UserAvatar } from 'components/Avatars';

export const ExpertTagsShow = (props) => (
  <Show title={<ViewTitle source='name' />} {...props}>
    <SimpleShowLayout>
      <TextField source='id' label='ID' />
      <ReferenceField
        source='expert_uuid'
        reference='experts'
        label='Expert'
        link={(record, reference) => `/${reference}/${record.expert_uuid}/show`}
      >
        <FunctionField
          render={(record) => (
            <AvatarContainer>
              <UserAvatar url={record?.thumbnail_url || null} />
              {record.nickname}
            </AvatarContainer>
          )}
        />
      </ReferenceField>
      <ReferenceField
        source='tag_uuid'
        reference='tags'
        label='Tag'
        link={(record, reference) => `/${reference}/${record.tag_uuid}/show`}
      >
        <FunctionField render={(record) => <>{record.name}</>} />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);
