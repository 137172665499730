import React from 'react';
import { ChipField, List, TextField } from 'react-admin';

import DataGridList from 'components/containers/List';
import LanguagesFilter from './filter';
import ListActions from 'components/ListAction/ListActions';
import EmptyPage from 'components/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';

export const LanguagesList = (props) => (
  <List
    filters={<LanguagesFilter />}
    pagination={<Paginator />}
    sort={{ field: 'id', order: 'DESC' }}
    perPage={15}
    {...props}
    empty={<EmptyPage create />}
    actions={<ListActions listExporterType='Languages' />}
  >
    <DataGridList show edit>
      <ChipField source='iso' label='ISO' />
      <TextField source='name' />
    </DataGridList>
  </List>
);
