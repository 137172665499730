import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
}));

export const AvatarContainer = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};
