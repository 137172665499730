import { Resource } from 'react-admin';
import ExpertsIcon from '@material-ui/icons/StarBorderRounded';
import reviewViews from './Reviews';
import expertViews from './Expert';
import skillsViews from './Skills';
import applicationViews from './Application';
import brandingViews from './Branding';
import expertTagsViews from './ExpertTags';

const ExpertsRoutes = [
  <Resource
    key='expert'
    name='expert'
    icon={ExpertsIcon}
    options={{ label: 'Experts', isMenuParent: true }}
  />,
  <Resource key='expert-views' {...expertViews} />,
  <Resource key='application-views' {...applicationViews} />,
  <Resource key='branding-views' {...brandingViews} />,
  <Resource key='skills-views' {...skillsViews} />,
  <Resource key='review-views' {...reviewViews} />,
  <Resource key='expert-tags-views' {...expertTagsViews} />,
];
export default ExpertsRoutes;
