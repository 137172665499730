import DataGridList from 'components/containers/List';
import ListActions from 'components/ListAction/ListActions';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import React from 'react';
import {
  List,
  ReferenceField,
  FunctionField,
  TextField,
  BooleanField,
} from 'react-admin';
import TagsFilter from './filter';
import { AvatarContainer, UserAvatar } from 'components/Avatars';

export const TagsList = (props) => (
  <List
    title='Tags'
    filters={<TagsFilter />}
    pagination={<Paginator />}
    perPage={25}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
    empty={<EmptyPage create />}
    actions={<ListActions listExporterType='Tags' />}
  >
    <DataGridList show edit destroy>
      <ReferenceField
        source='owner_id'
        reference='brands'
        label='Owner'
        link={(record, reference) => `/${reference}/${record.owner_id}/show`}
      >
        <FunctionField
          render={(record) => (
            <AvatarContainer>
              <UserAvatar url={record?.thumbnail_url || null} />
              {record.name}
            </AvatarContainer>
          )}
        />
      </ReferenceField>
      <TextField source='name' label='Name' />
      <BooleanField source='is_elevation' label='Elevation' />
    </DataGridList>
  </List>
);
