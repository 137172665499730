import React from 'react';
import { ViewTitle } from '../../../components/AppBar/Titles';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  FunctionField,
  ChipField,
  ArrayField,
  SingleFieldList,
} from 'react-admin';
import { AvatarContainer, UserAvatar } from 'components/Avatars';
import ActiveExpert from './components/ActiveExpert';
import StatusChip from 'components/common/StatusChip';
import { TICKET_STATUS } from './constants';
import ConversationsHistory from './components/ConversationsHistory';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    minWidth: '180px',
    maxWidth: '300px',
  },
}));

export const ConversationShow = (props) => {
  const classes = useStyles();

  return (
    <Show
      aside={<ConversationsHistory />}
      title={<ViewTitle source='name' />}
      classes={{
        card: classes.container,
      }}
      {...props}
    >
      <SimpleShowLayout>
        <ReferenceField
          source='brand_id'
          reference='brands'
          label='Brand'
          link={(record, reference) => `/${reference}/${record.brand_id}/show`}
        >
          <FunctionField
            render={(record) => (
              <AvatarContainer>
                <UserAvatar url={record?.thumbnail_url || null} />
                {record.name}
              </AvatarContainer>
            )}
          />
        </ReferenceField>
        <FunctionField
          label='Expert'
          render={(record) => <ActiveExpert record={record} />}
        />
        <ReferenceField
          source='user_id'
          reference='users'
          label='User'
          link={(record, reference) => `/${reference}/${record.user_id}/show`}
        >
          <FunctionField render={(record) => `${record.name} (${record.id})`} />
        </ReferenceField>
        <ReferenceField
          label='Season'
          source='season_id'
          reference='seasons'
          link={false}
        >
          <TextField source='number' />
        </ReferenceField>

        <ArrayField source='tags'>
          <SingleFieldList>
            <ChipField source='name' />
          </SingleFieldList>
        </ArrayField>

        <FunctionField
          sortBy='status'
          label='Status'
          render={(record) => {
            switch (record.status) {
              case TICKET_STATUS.FINALIZED:
              case TICKET_STATUS.SOLVED:
                return <StatusChip record={record} borderColor='#44b700' />;

              case TICKET_STATUS.PENDING_DISPUTE:
                return <StatusChip record={record} borderColor='#e62424' />;

              default:
                return <StatusChip record={record} borderColor='#eead2d' />;
            }
          }}
        />
        <TextField source='number_of_requeues' label='Requeues' />
        <TextField source='minutes_in_queue' label='Minutes in queue' />
        <TextField source='priority' label='Priority' />
        <TextField source='tcv' label='TCV' />
      </SimpleShowLayout>
    </Show>
  );
};
