import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExportButtonIcon from '@material-ui/icons/GetApp';
import ExcelIcon from '@material-ui/icons/GridOn';
import PDFIcon from '@material-ui/icons/PictureAsPdf';
import CSVIcon from '@material-ui/icons/Subject';
import 'jspdf-autotable';
import { HelperListExporter } from 'ListExporters/helper';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { ExportButton, useListContext } from 'react-admin';
import { exportToExcel, exportToCSV, exportToPDF } from './exportUtils';

const EXPORT_BUTTONS = [
  {
    type: 'EXCEL',
    icon: <CSVIcon />,
    active: true,
  },
  {
    type: 'CSV',
    icon: <ExcelIcon />,
    active: true,
  },
  {
    type: 'PDF',
    icon: <PDFIcon />,
    active: false,
  },
];

const ExportDataButton = (props) => {
  const [exportButtonAnchorEl, setExportButtonAnchorEl] = useState(null);
  let dynamicExportFileType = '';
  const { type } = props;
  const { currentSort, resource, filterValues, total } = useListContext();

  const handleClick = (event) => {
    setExportButtonAnchorEl(event.currentTarget);
  };

  const handleClose = (exportFileType) => {
    setExportButtonAnchorEl(null);
    dynamicExportFileType = exportFileType;
  };

  const finalExporter = async (records, fetchRelatedRecords) => {
    const finalExporterData = await HelperListExporter(
      records,
      fetchRelatedRecords,
      pluralize(type),
    );

    switch (dynamicExportFileType) {
      case 'EXCEL':
        exportToExcel(finalExporterData);
        break;

      case 'CSV':
        exportToCSV(finalExporterData);
        break;

      case 'PDF':
        exportToPDF(finalExporterData, resource);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Button
        aria-controls='simple-menu'
        aria-haspopup='true'
        size='small'
        onClick={handleClick}
        startIcon={<ExportButtonIcon />}
      >
        EXPORT
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={exportButtonAnchorEl}
        keepMounted
        open={Boolean(exportButtonAnchorEl)}
        onClose={handleClose}
      >
        {EXPORT_BUTTONS.map(
          (btn) =>
            btn.active && (
              <MenuItem
                key={btn.type}
                onClick={() => handleClose(btn.type)}
                disabled={total === 0}
              >
                <ExportButton
                  resource={resource}
                  sort={currentSort}
                  filterValues={filterValues}
                  // TODO: release the 100 limit
                  maxResults={resource === 'subscriptions' ? 100 : total}
                  exporter={finalExporter}
                  label={btn.type}
                  icon={btn.icon}
                  style={{ backgroundColor: 'transparent' }}
                />
              </MenuItem>
            ),
        )}
      </Menu>
    </>
  );
};

export default ExportDataButton;
