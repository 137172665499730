import DataGridList from 'components/containers/List';
import ListActions from 'components/ListAction/ListActions';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import React from 'react';
import {
  ChipField,
  FunctionField,
  List,
  NumberField,
  TextField,
  ReferenceField,
} from 'react-admin';
import ReviewsFilter from './filter';

export const ReviewsList = (props) => (
  <List
    title='Reviews'
    filters={<ReviewsFilter />}
    pagination={<Paginator />}
    perPage={15}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
    empty={<EmptyPage create />}
    actions={<ListActions listExporterType='Reviews' hideCreateButton='true' />}
  >
    <DataGridList show edit>
      <ReferenceField
        source='reviewable_id'
        reference='experts'
        label='Expert'
        link={(record, reference) =>
          `/${reference}/${record.reviewable_id}/show`
        }
      >
        <TextField source='nickname' />
      </ReferenceField>

      <ReferenceField
        source='reviewer_id'
        reference='users'
        label='User'
        link={(record, reference) => `/${reference}/${record.reviewer_id}/show`}
      >
        <TextField source='name' />
      </ReferenceField>
      <NumberField source='rating' label='Rating' />
      <TextField source='comment' label='comment' />

      <FunctionField
        sortBy='status'
        label='Status'
        render={(record) => {
          switch (record.status) {
            case 'approved':
              return (
                <ChipField
                  record={record}
                  source='status'
                  variant='outlined'
                  style={{
                    textTransform: 'capitalize',
                    borderColor: '#44b700',
                  }}
                />
              );
            case 'rejected':
              return (
                <ChipField
                  record={record}
                  source='status'
                  variant='outlined'
                  style={{
                    textTransform: 'capitalize',
                    borderColor: '#e62424',
                  }}
                />
              );
            case 'pending-approval':
              return (
                <ChipField
                  record={record}
                  source='status'
                  variant='outlined'
                  style={{
                    textTransform: 'capitalize',
                  }}
                />
              );

            default:
              break;
          }
        }}
      />
    </DataGridList>
  </List>
);
