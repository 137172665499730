import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import HeyExpertLoader from './Loading.json';
import Lottie from 'react-lottie';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 3000,
    color: '#fff',
  },
}));

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: HeyExpertLoader,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const BackdropLoader = () => {
  const classes = useStyles();

  const loading = useSelector((state) => state.admin.loading > 0);

  return (
    <Backdrop className={classes.backdrop} open={loading ? true : false}>
      <Lottie options={defaultOptions} height={50} width={50} />
    </Backdrop>
  );
};

export default BackdropLoader;
