import FilterContainer from 'components/containers/Filter';
import React from 'react';
import { SelectInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { DebouncedTextField } from 'components/Fields';
import {
  EmailField,
  PhoneField,
} from '../../../components/Fields/DebouncedFields/DebounceField/DebounceField';

const ExpertsFilter = (props) => (
  <FilterContainer {...props} variant='standard' margin='normal'>
    <DebouncedTextField label='Display Name' source='nickname' />

    <EmailField source='email' label='Email' />

    <DebouncedTextField
      label='Wallet Address'
      source='additionalInformation->publicId'
    />

    <SelectInput
      source='status'
      label='Status'
      choices={[
        { id: 'pending', name: 'Pending' },
        { id: 'active', name: 'Active' },
        { id: 'inactive', name: 'Inactive' },
        { id: 'suspended', name: 'Soft Revoked' },
        { id: 'suspended', name: 'Hard Revoked' },
      ]}
    />

    <PhoneField label='Mobile Number' source='phone_number' />
    <DebouncedTextField source='sort_index' label='Sort index' />

    <ReferenceInput
      label='Time Zone'
      source='time_zone_id'
      reference='time_zones'
      perPage={25}
      filterToQuery={(searchText) => ({
        iso: searchText,
        name: searchText,
        _autocomplete: true,
      })}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput
        matchSuggestion={() => true}
        optionText={(choice) =>
          choice?.id ? `${choice.name} (${choice.iso})` : 'None'
        }
      />
    </ReferenceInput>
    <SelectInput
      source='is_score_filtered'
      label='Is score filtered'
      choices={[
        { id: true, name: 'True' },
        { id: false, name: 'False' },
      ]}
    />
  </FilterContainer>
);

export default ExpertsFilter;
