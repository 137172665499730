import React from 'react';
import { EditTitle } from '../../../components/AppBar/Titles';
import { EditToolbar } from '../../../components/Toolbar/EditToolbar';
import {
  Edit,
  SimpleForm,
  SelectInput,
  required,
  FormDataConsumer,
} from 'react-admin';

const optionalStatusesGenerator = (status) => {
  switch (status) {
    case 'pending':
      return [
        { id: 'pending', name: 'Pending' },
        { id: 'pending kyc', name: 'Request KYC' },
        { id: 'approved', name: 'Approve' },
        { id: 'declined', name: 'Decline' },
      ];

    case 'pending kyc':
    case 'kyc approved':
    case 'kyc declined':
      return [
        { id: status, name: status },
        { id: 'approved', name: 'Approve' },
        { id: 'declined', name: 'Decline' },
      ];

    case 'approved':
    case 'declined':
      return [{ id: status, name: status }];

    default:
      return [
        { id: 'pending', name: 'Pending' },
        { id: 'pending kyc', name: 'Request KYC' },
        { id: 'approved', name: 'Approve' },
        { id: 'declined', name: 'Decline' },
      ];
  }
};

export const ApplicationsEdit = (props) => (
  <Edit title={<EditTitle source='name' />} {...props}>
    <SimpleForm variant='standard' margin='normal' toolbar={<EditToolbar />}>
      <FormDataConsumer>
        {({ formData }) => (
          <SelectInput
            source='status'
            validate={required()}
            fullWidth
            choices={optionalStatusesGenerator(formData.status)}
          />
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);
