import React from 'react';
import { Show, SimpleShowLayout, TextField, BooleanField } from 'react-admin';
import { ViewTitle } from '../../../components/AppBar/Titles';

export const SkillShow = (props) => (
  <Show title={<ViewTitle source='name' />} {...props}>
    <SimpleShowLayout>
      <TextField source='id' label='ID' />
      <TextField source='name' />
      <BooleanField source='is_public' label='Is Public' />
    </SimpleShowLayout>
  </Show>
);
