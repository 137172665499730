import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Popover,
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore,
  ViewList as DefaultIcon,
} from '@material-ui/icons';
import { useRedirect } from 'react-admin';

const ParentMenu = ({
  open,
  parent,
  onParentClick,
  isParentOpen,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const hasChildren = props.children.length > 0;
  const openPopOver = Boolean(anchorEl);
  const redirect = useRedirect();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const ParentClickHandler = (e) => {
    if (!hasChildren) {
      redirect(`/${parent.name}`);
      return;
    }
    if (!open) {
      handleClick(e);
    } else {
      onParentClick(e);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ListItem button onClick={ParentClickHandler}>
        <ListItemIcon>
          {parent?.icon ? <parent.icon /> : <DefaultIcon />}
        </ListItemIcon>
        <ListItemText primary={parent?.options?.label} />
        {hasChildren && (isParentOpen ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>
      {open ? (
        <Collapse in={isParentOpen} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {props.children}
          </List>
        </Collapse>
      ) : (
        <Popover
          open={openPopOver}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <List component='div' disablePadding>
            {props.children}
          </List>
        </Popover>
      )}
    </>
  );
};

export default ParentMenu;
