import tagsIcon from '@material-ui/icons/Label';
import { TagsList } from './list';
import { TagsCreate } from './create';
import { TagsEdit } from './edit';
import { TagShow } from './show';

export default {
  key: 'tags',
  name: 'tags',
  options: { label: 'Tags', menuParent: 'brand' },
  icon: tagsIcon,
  list: TagsList,
  show: TagShow,
  create: TagsCreate,
  edit: TagsEdit,
};
