import React from 'react';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';
import {
  Create,
  SimpleForm,
  SelectInput,
  required,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { JsonInput } from 'react-admin-json-view';

export const TriggersCreate = (props) => (
  <Create {...props} title='Create Trigger'>
    <SimpleForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
      <ReferenceInput
        validate={required()}
        source='owner_id'
        reference='users'
        label='Owner'
        perPage={10}
        filterToQuery={(searchText) => ({
          id: parseInt(searchText),
          first_name: searchText,
          last_name: searchText,
          name: searchText,
          _autocomplete: true,
        })}
        sort={{ field: 'id', order: 'ASC' }}
        fullWidth
      >
        <AutocompleteInput
          matchSuggestion={() => true}
          optionText={(choice) =>
            choice?.id ? `${choice.name} (${choice.id})` : 'None'
          }
        />
      </ReferenceInput>
      <SelectInput
        fullWidth
        validate={required()}
        source='type'
        label='Type'
        choices={[{ id: 'PendingReview', name: 'PendingReview' }]}
      />
      <JsonInput
        source='payload'
        addLabel={false}
        validate={[required()]}
        reactJsonOptions={{
          name: null,
          collapsed: true,
          theme: 'marrakesh',
          displayDataTypes: false,
          style: {
            width: '80.5vw',
            minHeight: '300px',
            borderRadius: '3px',
            padding: '20px',
            fontSize: '15px',
            backgroundColor: 'rgb(39, 40, 34)',
          },
        }}
      />
      <NumberInput source='priority' fullWidth />
    </SimpleForm>
  </Create>
);
