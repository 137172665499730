import { Box, Typography } from '@material-ui/core';
import moment from 'moment';

const MessageBox = (props) => {
  const { index, message, fullWidth } = props;
  const isUser = message.ownerType === 'User';

  return (
    <Box
      key={index}
      sx={{
        minWidth: '150px',
        maxWidth: fullWidth ? '100%' : '70%',
        backgroundColor: isUser ? '#248ad3' : '#303030',
        padding: '10px',
        margin: '10px 5px',
        alignSelf: isUser ? 'flex-start' : 'flex-end',
        borderRadius: '16px',
      }}
    >
      <Typography variant='body1' gutterBottom>
        {message.text}
      </Typography>
      <Box
        sx={{
          fontSize: '0.875rem',
          color: isUser ? '#303030' : 'rgb(158, 158, 158) !important',
          float: 'right',
        }}
      >
        {moment(message.createdAt).format('HH:mm D MMM YYYY')}
      </Box>
    </Box>
  );
};

export default MessageBox;
