import React from 'react';
import UsersFilter from './filter';
import EmptyPage from 'components/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import ListActions from 'components/ListAction/ListActions';
import { UserAvatar } from 'components/Avatars/UserAvatar';
import { AvatarContainer } from 'components/Avatars/AvatarContainer';
import { List, TextField, FunctionField } from 'react-admin';
import { DateTimeField } from 'components/Fields';
import DataGridList from 'components/containers/List';

export const UsersList = (props) => (
  <List
    filters={<UsersFilter />}
    pagination={<Paginator />}
    perPage={15}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
    empty={<EmptyPage />}
    actions={<ListActions listExporterType='Users' hideCreateButton />}
  >
    <DataGridList show>
      <FunctionField
        label='Name'
        sortBy='nickname'
        render={(record) => (
          <AvatarContainer>
            <UserAvatar url={record?.thumbnail_url || null} />
            {record.nickname}
          </AvatarContainer>
        )}
      />
      <TextField source='email' />

      <DateTimeField source='updated_at' label='Last activity' />
    </DataGridList>
  </List>
);
