import config from '../config';
import crypto from 'crypto';

const getContextFromResource = (resource) => {
  switch (resource) {
    case 'admin_users':
      return 'admins';
    case 'brands':
      return 'images';
    case 'experts':
    case 'representatives':
    case 'users':
    default:
      return resource;
  }
};

export const uploadFile = async (
  uploadable,
  file,
  resource,
  additionalParameters,
) => {
  const token = localStorage.getItem('token');
  if (file) {
    const { rawFile } = file;

    if (rawFile) {
      const extension = rawFile.name.split('.').pop();

      const presignedUrlRequest = new Request(
        `${config.API_URL}/resources/${resource}/presigned_urls`,
        {
          method: 'POST',
          body: JSON.stringify({
            file_name: `${uploadable}.${extension}`,
            context: getContextFromResource(resource),
            ...additionalParameters,
          }),
          headers: new Headers({
            'Content-Type': 'application/json',
            'X-Auth-Token': token,
          }),
        },
      );
      const presignedUrlResponse = await fetch(presignedUrlRequest);
      const presignedUrlResponseJson = await presignedUrlResponse.json();
      const { key, url } = presignedUrlResponseJson.data;

      const s3UploadRequest = new Request(url, {
        method: 'PUT',
        body: rawFile,
      });

      await fetch(s3UploadRequest);

      return key;
    } else {
      return file.name;
    }
  }

  return '';
};

export const uploadLargeFile = async (
  uploadable,
  file,
  resource,
  additionalParameters,
) => {
  const token = localStorage.getItem('token');
  const CHUNK_SIZE = 50_000_000;
  let byteIndex = 0;
  if (file) {
    const { rawFile, key } = file;
    if (rawFile) {
      const CHUNK_AMOUNT = Math.ceil(rawFile.size / CHUNK_SIZE);
      const extension = rawFile.name.split('.').pop();
      const presignedUrlRequest = new Request(
        `${config.API_URL}/resources/${resource}/presigned_urls`,
        {
          method: 'POST',
          body: JSON.stringify({
            file_name: `${uploadable}.${extension}`,
            context: getContextFromResource(resource),
            ...additionalParameters,
          }),
          headers: new Headers({
            'Content-Type': 'application/json',
            'X-Auth-Token': token,
          }),
        },
      );
      const presignedUrlResponse = await fetch(presignedUrlRequest);
      const presignedUrlResponseJson = await presignedUrlResponse.json();
      const { key, url } = presignedUrlResponseJson.data;
      const uniqueId = crypto.randomBytes(20).toString('hex');
      const fetcher = [];
      for (let i = 0; i < CHUNK_AMOUNT; i++) {
        const chunkForm = createChunk(rawFile, byteIndex, CHUNK_SIZE);
        const chunkEnd = Math.min(byteIndex + CHUNK_SIZE, rawFile.size);

        fetcher.push(
          fetch(
            new Request(url, {
              method: 'PUT',
              body: chunkForm,
              headers: new Headers({
                'Content-Range': `bytes ${byteIndex}-${chunkEnd - 1}/${
                  rawFile.size
                }`,
                'X-Unique-Upload-Id': uniqueId,
              }),
            }),
          ),
        );
        byteIndex += CHUNK_SIZE;
      }
      return Promise.all(fetcher).then(() => {
        return key;
      });
    } else {
      return key;
    }
  }

  return '';
};

const createChunk = (file, start, chunk_size) => {
  const chunkEnd = Math.min(start + chunk_size, file.size);
  const chunk = file.slice(start, chunkEnd);
  const chunkForm = new FormData();
  chunkForm.append('file', chunk);
  return chunkForm;
};
