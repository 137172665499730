import {
  Search,
  Email,
  Phone,
  SupervisorAccount,
  Event,
  VpnKey,
} from '@material-ui/icons';
import DebouncedTextField from '../DebouncedTextField';
export const SearchField = (props) => {
  return (
    <DebouncedTextField
      alwaysOn
      Icon={Search}
      placeholder='Search'
      {...props}
    />
  );
};

export const EmailField = (props) => {
  return <DebouncedTextField label='Email' Icon={Email} {...props} />;
};

export const PhoneField = ({ label = 'Mobile Number', ...props }) => {
  return (
    <DebouncedTextField label={label} Icon={Phone} type='number' {...props} />
  );
};

export const AdminNameField = (props) => {
  return (
    <DebouncedTextField
      label='Admin Name'
      Icon={SupervisorAccount}
      {...props}
    />
  );
};

export const EventNameField = () => {
  return <DebouncedTextField label='Event Name' Icon={Event} />;
};

export const KeyField = () => {
  return <DebouncedTextField label='Key' Icon={VpnKey} />;
};
