import React, { useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const SortableItem = SortableElement(({ value }) => (
  <div
    style={{
      float: 'left',
      width: '50px',
      marginRight: '10px',
      cursor: 'grab',
      whiteSpace: 'nowrap',
      zIndex: '999',
      // "&:active": { cursor: "grabbing" },
    }}
  >
    {value}
  </div>
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <div style={{ display: 'inline-block' }}>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </div>
  );
});

export const ArrayInputDragAndDrop = () => {
  const [state, setState] = useState({
    items: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6'],
  });
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setState({
      items: arrayMoveImmutable(state.items, oldIndex, newIndex),
    });
  };

  return (
    <SortableList
      items={state.items}
      onSortEnd={onSortEnd}
      lockAxis='x'
      axis='x'
    />
  );
};
