import CitiesIcon from '@material-ui/icons/LocationCityRounded';
import { CitiesList } from './list';
import { CitiesCreate } from './create';
import { CitiesShow } from './show';
import { CitiesEdit } from './edit';

export default {
  name: 'cities',
  options: { label: 'Cities', menuParent: 'geo' },
  icon: CitiesIcon,
  list: CitiesList,
  show: CitiesShow,
  create: CitiesCreate,
  edit: CitiesEdit,
};
