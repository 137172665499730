import React from 'react';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
} from 'react-admin';

export const TagsCreate = (props) => (
  <Create {...props} title='Create Tag'>
    <SimpleForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
      <ReferenceInput
        validate={required()}
        source='owner_id'
        reference='brands'
        label='Owner'
        perPage={10}
        filterToQuery={(searchText) => ({
          id: parseInt(searchText),
          name: searchText,
          _autocomplete: true,
        })}
        sort={{ field: 'id', order: 'ASC' }}
        fullWidth
      >
        <AutocompleteInput
          matchSuggestion={() => true}
          optionText={(choice) =>
            choice?.id ? `${choice.name} (${choice.id})` : 'None'
          }
        />
      </ReferenceInput>
      <TextInput source='name' validate={required()} fullWidth />
      <BooleanInput source='is_elevation' label='Elevation' fullWidth />
    </SimpleForm>
  </Create>
);
