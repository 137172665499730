import { DateFieldShow } from 'components/Fields';
import {
  BooleanField,
  ImageField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import React from 'react';
import { ViewTitle } from '../../../components/AppBar/Titles';

export const NotificationTypesShow = (props) => (
  <Show title={<ViewTitle source='name' />} {...props}>
    <SimpleShowLayout>
      <TextField source='id' label='ID' />
      <TextField source='name' />
      <TextField source='title_pattern' />
      <TextField source='body_pattern' />
      <ImageField source='thumbnail_url' label='Thumbnail' />
      <BooleanField source='use_sender_thumbnail' />
      <BooleanField source='send_push' />
      <BooleanField source='send_sms' />

      <ReferenceField
        source='client_action_id'
        reference='client_actions'
        label='Client Action'
      >
        <TextField source='name' />
      </ReferenceField>
      <DateFieldShow label='Created At' source='created_at' />
      <DateFieldShow label='Updated At' source='updated_at' />
    </SimpleShowLayout>
  </Show>
);
