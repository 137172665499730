import React from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import { ViewTitle } from '../../../components/AppBar/Titles';
import {
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  ImageField,
  EmailField,
  ReferenceField,
  ChipField,
} from 'react-admin';

export const RepresentativesShow = (props) => (
  <Show title={<ViewTitle source='email' />} {...props}>
    <SimpleShowLayout>
      <TextField source='id' label='ID' />
      <TextField source='first_name' label='First Name' />
      <TextField source='last_name' label='Last Name' />
      <EmailField source='email' label='Email(s)' />
      <ReferenceField source='brand_id' reference='brands' label='Brand'>
        <TextField source='name' />
      </ReferenceField>
      <TextField source='type' label='Type' />
      <FunctionField
        sortBy='status'
        label='Status'
        render={(record) => {
          switch (record.status) {
            case 'active':
              return (
                <ChipField
                  record={record}
                  source='status'
                  variant='outlined'
                  style={{
                    textTransform: 'capitalize',
                    borderColor: '#44b700',
                  }}
                />
              );

            case 'inactive':
              return (
                <ChipField
                  record={record}
                  source='status'
                  variant='outlined'
                  style={{
                    textTransform: 'capitalize',
                    borderColor: '#e62424',
                  }}
                />
              );

            case 'pending':
              return (
                <ChipField
                  record={record}
                  source='status'
                  variant='outlined'
                  style={{
                    textTransform: 'capitalize',
                    borderColor: '#e62424',
                  }}
                />
              );

            case 'suspended':
              return (
                <ChipField
                  record={record}
                  source='status'
                  variant='outlined'
                  style={{ textTransform: 'capitalize' }}
                />
              );

            default:
              break;
          }
        }}
      />
      <ImageField source='thumbnail_url' label='Thumbnail' />
      <FunctionField
        label='Created At'
        source='created_at'
        render={(record) =>
          record.created_at && (
            <Moment format='MM/DD/YYYY'>{moment(record.created_at)}</Moment>
          )
        }
      />

      <FunctionField
        label='Updated At'
        source='updated_at'
        render={(record) =>
          record.updated_at && (
            <Moment format='MM/DD/YYYY'>{moment(record.updated_at)}</Moment>
          )
        }
      />
    </SimpleShowLayout>
  </Show>
);
