import { DateFieldShow } from 'components/Fields';
import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ChipField,
  BooleanField,
} from 'react-admin';
import { ViewTitle } from '../../../components/AppBar/Titles';

export const CountriesShow = (props) => (
  <Show title={<ViewTitle source='name' />} {...props}>
    <SimpleShowLayout>
      <TextField source='id' label='ID' />
      <TextField source='name' />
      <ChipField source='iso' />
      <BooleanField source='isBlocked' />
      <TextField source='code' />
      <DateFieldShow label='Created At' source='created_at' />
      <DateFieldShow label='Updated At' source='updated_at' />
    </SimpleShowLayout>
  </Show>
);
