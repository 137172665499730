import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useListContext } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
const useStyles = makeStyles((theme) => ({
  linkButton: {
    borderRadius: 0,
    marginTop: '1.5rem',
  },
  createLinkButton: {
    borderRadius: 0,
    marginTop: '1.5rem',
    marginLeft: '1.5rem',
  },
  linkContent: {
    color: theme.palette.primary.main,
    // padding: "0.5rem",
  },
}));

const EmptyPage = ({ create }) => {
  const { resource } = useListContext();
  const modifiedResourceName = resource.replace(/_/g, ' ');
  const classes = useStyles();

  return (
    <Box textAlign='left' m={4}>
      <Typography variant='h4' paragraph>
        No {modifiedResourceName === 'groups' ? 'hubs' : modifiedResourceName}{' '}
        available.
      </Typography>
      <Typography variant='body1'>
        We didn&apos;t anticipate this taking so long.
      </Typography>
      <Button
        className={classes.linkButton}
        component={Link}
        to='/'
        size='small'
        color='primary'
      >
        <Box className={classes.linkContent}>Go to Dashboard</Box>
      </Button>
      {create && (
        <Button
          className={classes.createLinkButton}
          component={Link}
          to={`/${resource}/create`}
          size='small'
          color='primary'
        >
          <Box className={classes.linkContent}>Create</Box>
          <AddIcon />
        </Button>
      )}
    </Box>
  );
};

export default EmptyPage;
