import { Box } from '@material-ui/core';
import BoldText from 'components/common/BoldText';
import { formatUnits } from 'ethers';

const SeasonDetails = (props) => {
  const { title, season } = props;

  const getFundedText = () => {
    if (season.funding?.length > 0) {
      const parsedValues = season.funding.map((f) => {
        const parsedValue = formatUnits(f.value, f.decimals);
        return `${parsedValue} ${f.symbol}`;
      });

      return `Funded ${parsedValues.join(', ')}`;
    }

    return 'Not Funded';
  };

  return (
    <Box sx={{ lineHeight: '22px' }}>
      <BoldText>{title}</BoldText>
      <Box>
        {`Season ${season.number}, ${
          season.formattedStartDate || 'To be announced'
        } -
        ${getFundedText()}`}
      </Box>
    </Box>
  );
};

export default SeasonDetails;
