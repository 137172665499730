import React from 'react';
import { EditTitle } from '../../../components/AppBar/Titles';
import { EditToolbar } from '../../../components/Toolbar/EditToolbar';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
} from 'react-admin';

export const SettingsEdit = (props) => (
  <Edit title={<EditTitle source='name' />} {...props}>
    <SimpleForm variant='standard' margin='normal' toolbar={<EditToolbar />}>
      <TextInput source='key' validate={required()} fullWidth />
      <TextInput source='value' validate={required()} fullWidth />
      <TextInput source='description' fullWidth />
      <BooleanInput
        source='is_client_accessible'
        label='Is Client Accessible'
        fullWidth
      />
    </SimpleForm>
  </Edit>
);
