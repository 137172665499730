// default config for all the environments
const defaultConfig = {
  language: 'en',
};

// write here every environment configuration
const modes = {
  development: {
    ...defaultConfig,
    // API_URL: 'http://localhost:3000/api/v1/admin', // local server
    API_URL: process.env.REACT_APP_API_URL, // dev server
  },
  staging: {
    ...defaultConfig,
    API_URL: process.env.REACT_APP_API_URL,
  },
  production: {
    ...defaultConfig,
    API_URL: process.env.REACT_APP_API_URL,
  },
};

const appMode = modes[process.env.REACT_APP_ENV] || modes.development;

export default appMode;

const useId = {
  development: 236,
  staging: 236,
  qa: 236,
  production: 236,
};
export const usaMode = useId[process.env.REACT_APP_ENV] || useId.development;
