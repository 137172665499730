import React from 'react';
import GeographicIcon from '@material-ui/icons/PublicRounded';

import { Resource } from 'react-admin';
import citiesViews from './City';
import statesViews from './State';
import countriesViews from './Country';

const GeoRoutes = [
  <Resource
    key='geo'
    name='geo'
    icon={GeographicIcon}
    options={{ label: 'Geographic', isMenuParent: true }}
  />,
  <Resource key='cities-views' {...citiesViews} />,
  <Resource key='countries-views' {...countriesViews} />,
  <Resource key='states-views' {...statesViews} />,
];

export default GeoRoutes;
