import LanguagesIcon from '@material-ui/icons/TranslateRounded';

import { LanguageEdit } from './edit';
import { LanguagesList } from './list';
import { LanguagesShow } from './show';
import { LanguagesCreate } from './create';

export default {
  name: 'languages',
  options: { label: 'Language', menuParent: 'localization' },
  icon: LanguagesIcon,
  list: LanguagesList,
  show: LanguagesShow,
  create: LanguagesCreate,
  edit: LanguageEdit,
};
