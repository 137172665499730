import StatesIcon from '@material-ui/icons/MapRounded';
import { StatesList } from './list';
import { StatesCreate } from './create';
import { StatesShow } from './show';
import { StatesEdit } from './edit';

export default {
  name: 'states',
  options: { label: 'States', menuParent: 'geo' },
  icon: StatesIcon,
  list: StatesList,
  show: StatesShow,
  create: StatesCreate,
  edit: StatesEdit,
};
