import { useEffect, useState } from 'react';
import { Contract, getDefaultProvider } from 'ethers';

const SIMPLIFIED_ERC20_ABI = [
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'decimals',
    outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'symbol',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalSupply',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
];

function useERC20(rpcURL) {
  const [tokensInfo, setTokensInfo] = useState(new Map()); // Used to cache tokens information
  const [provider, setProvider] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [invalidTokens, setInvalidTokens] = useState([]);

  const getFromContract = async (name, tokenAddress) => {
    if (isLoading || invalidTokens.includes(tokenAddress)) {
      return;
    }

    let tokenInfo = tokensInfo.get(tokenAddress) || {};
    if (tokenInfo[name]) {
      return tokenInfo[name];
    }

    try {
      const ERC20SmartContract = new Contract(
        tokenAddress,
        SIMPLIFIED_ERC20_ABI,
        provider,
      );

      const value = await ERC20SmartContract[name]();
      tokenInfo[name] = value;

      // Update tokens info map
      setTokensInfo((infoMap) => {
        infoMap.set(tokenAddress, tokenInfo);
        return infoMap;
      });

      return value;
    } catch (error) {
      // If we catch an error probably the address does not belong to a valid token
      console.log(
        `Was not possible to retrieve ${name} for token with address: ${tokenAddress}`,
      );
      setInvalidTokens((invalidTokens) => {
        invalidTokens.push(tokenAddress);
        return invalidTokens;
      });
    }
  };

  const getSymbol = async (tokenAddress) => {
    return getFromContract('symbol', tokenAddress);
  };

  const getDecimals = async (tokenAddress) => {
    return getFromContract('decimals', tokenAddress);
  };

  const getBalanceOf = async (tokenAddress) => {
    return getFromContract('balanceOf', tokenAddress);
  };

  const getTotalSupply = async (tokenAddress) => {
    return getFromContract('totalSupply', tokenAddress);
  };

  useEffect(() => {
    if (!rpcURL) {
      return;
    }
    setProvider(getDefaultProvider(rpcURL));
    setIsLoading(false);
  }, [rpcURL]);

  return {
    getSymbol,
    getDecimals,
    getBalanceOf,
    getTotalSupply,
    isLoading,
  };
}

export default useERC20;
