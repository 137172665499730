import brandsIcon from '@material-ui/icons/AppsRounded';

import { BrandsList } from './list';
import { BrandsShow } from './show';
import { BrandsCreate } from './create';
import { BrandsEdit } from './edit';

export default {
  key: 'brands',
  name: 'brands',
  options: { label: 'Main', menuParent: 'brand' },
  icon: brandsIcon,
  list: BrandsList,
  show: BrandsShow,
  create: BrandsCreate,
  edit: BrandsEdit,
};
