import React from 'react';
import { Tooltip } from '@material-ui/core';

export const EditTitle = ({ record, source }) => {
  return (
    <Tooltip title={record ? `"${record[source]}"` : ''}>
      <span>Edit {record ? `"${record[source]}"` : ''}</span>
    </Tooltip>
  );
};

export const ViewTitle = ({ record, source }) => {
  return (
    <Tooltip title={record ? `"${record[source]}"` : ''}>
      <span>View {record ? `"${record[source]}"` : ''}</span>
    </Tooltip>
  );
};
