import brandsIcon from '@material-ui/icons/Forum';

import { ConversationsList } from './list';
import { ConversationShow } from './show';

export default {
  key: 'tickets',
  name: 'tickets',
  options: { label: 'Conversations', menuParent: 'monitoring' },
  icon: brandsIcon,
  list: ConversationsList,
  show: ConversationShow,
};
