import React from 'react';

const WarningIcon = () => {
  return (
    <svg
      width='32'
      height='28'
      viewBox='0 0 32 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.416626 27.75L16 0.833374L31.5833 27.75H0.416626ZM4.09996 25.625H27.9L16 5.08337L4.09996 25.625ZM16.1416 23.6063C16.4486 23.6063 16.7024 23.5059 16.9031 23.3052C17.1038 23.1046 17.2041 22.8507 17.2041 22.5438C17.2041 22.2368 17.1038 21.983 16.9031 21.7823C16.7024 21.5816 16.4486 21.4813 16.1416 21.4813C15.8347 21.4813 15.5809 21.5816 15.3802 21.7823C15.1795 21.983 15.0791 22.2368 15.0791 22.5438C15.0791 22.8507 15.1795 23.1046 15.3802 23.3052C15.5809 23.5059 15.8347 23.6063 16.1416 23.6063ZM15.0791 19.675H17.2041V11.7417H15.0791V19.675Z'
        fill='#FF5C00'
      />
    </svg>
  );
};
export default WarningIcon;
