import React from 'react';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';
import { Create, SimpleForm, TextInput, required } from 'react-admin';

export const ClientActionsCreate = (props) => (
  <Create {...props} title='Create ClientActions'>
    <SimpleForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
      <TextInput source='name' validate={required()} fullWidth />
      <TextInput
        source='required_linkable_type'
        label='Required Linkable Type'
        fullWidth
      />
    </SimpleForm>
  </Create>
);
