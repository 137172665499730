import React from 'react';
import FilterContainer from 'components/containers/Filter';
import { ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';

const ApplicationsFilter = (props) => (
  <FilterContainer {...props} variant='standard' margin='normal'>
    <ReferenceInput
      source='brand_id'
      reference='brands'
      label='Brand'
      perPage={25}
      filterToQuery={(searchText) => ({
        name: searchText,
        _autocomplete: true,
      })}
    >
      <AutocompleteInput matchSuggestion={() => true} optionText='name' />
    </ReferenceInput>
    <ReferenceInput
      source='expert_id'
      reference='experts'
      label='Expert'
      perPage={25}
      filterToQuery={(searchText) => ({
        nickname: searchText,
        _autocomplete: true,
      })}
    >
      <AutocompleteInput
        matchSuggestion={(filter, choice) => {
          return choice.nickname;
        }}
        optionText='nickname'
      />
    </ReferenceInput>
    <SelectInput
      source='status'
      label='Status'
      choices={[
        { id: 'pending', name: 'Pending' },
        { id: 'pending kyc', name: 'Pending KYC' },
        { id: 'kyc approved', name: 'KYC Approved' },
        { id: 'kyc declined', name: 'KYC Declined' },
        { id: 'approved', name: 'Approved' },
        { id: 'declined', name: 'Declined' },
      ]}
    />
  </FilterContainer>
);

export default ApplicationsFilter;
