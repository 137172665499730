import { ClientActionsList } from './list';
import { ClientActionsShow } from './show';
import { ClientActionEdit } from './edit';
import { ClientActionsCreate } from './create';
import ClientActionsIcon from '@material-ui/icons/CallToAction';

export default {
  name: 'client_actions',
  options: { label: 'ClientActions', menuParent: 'notifications' },
  icon: ClientActionsIcon,
  list: ClientActionsList,
  show: ClientActionsShow,
  create: ClientActionsCreate,
  edit: ClientActionEdit,
};
