import { IconButton } from '@material-ui/core';
import CopyIcon from 'assets/svgs/CopyIcon';
import { useNotify } from 'react-admin';

const CopyToClipboardButton = (props) => {
  const { textToCopy, successMessage } = props;
  const notify = useNotify();

  async function copyToClipboard() {
    try {
      await navigator.clipboard.writeText(textToCopy);
      notify(successMessage || 'Text copied to clipboard');
    } catch (e) {
      console.error('Failed to copy text: ', e);
    }
  }

  return (
    <IconButton onClick={copyToClipboard}>
      <CopyIcon />
    </IconButton>
  );
};

export default CopyToClipboardButton;
