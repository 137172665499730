import RepresentativesIcon from '@material-ui/icons/AppsRounded';

import { RepresentativeList } from './list';
import { RepresentativesShow } from './show';
import { RepresentativesEdit } from './edit';
import { RepresentativesCreate } from './create';

export default {
  name: 'representatives',
  options: { label: 'Main', menuParent: 'representative' },
  icon: RepresentativesIcon,
  list: RepresentativeList,
  show: RepresentativesShow,
  create: RepresentativesCreate,
  edit: RepresentativesEdit,
};
