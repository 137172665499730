import config from '../config';
import { fetchUtils } from 'react-admin';
import simpleRestProvider from './SimpleRestProvider';
import { set } from 'lodash';

const loadFiles = async (record) => {
  for (let [attribute, value] of Object.entries(record)) {
    if (value && Object.prototype.hasOwnProperty.call(value, 'url')) {
      const { key, url } = value;
      record[`${attribute}_file`] = { src: url, key };
    } else if (
      Array.isArray(value) &&
      value.every((obj) => typeof obj === 'object' && obj !== null)
    ) {
      const parentAttribute = attribute;
      for (let [index, object] of value.entries()) {
        for (let [attribute, value] of Object.entries(object)) {
          if (value && Object.prototype.hasOwnProperty.call(value, 'url')) {
            const { key, url } = value; // eslint-disable-line
            set(record, `${parentAttribute}[${index}].${attribute}_file`, {
              src: url,
              key,
            });
          }
        }
      }
    }
  }
};

export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const token = localStorage.getItem('token');
  options.headers.set('X-Auth-Token', token);
  return fetchUtils.fetchJson(url, options);
};

const _dataProvider = simpleRestProvider(
  `${config.API_URL}/resources`,
  httpClient,
);

const dataProvider = {
  ..._dataProvider,
  getList: async (resource, params) => {
    // changing the filters that has '->' inside their source to prevent wrong filter object structure:
    for (const key in params.filter) {
      if (key.includes('->')) {
        params.filter[`${key.replace(/->/g, '.')}`] = params.filter[key];
        delete params.filter[key];
      }
    }

    const records = await _dataProvider.getList(resource, params);
    return records;
  },
  getOne: async (resource, params) => {
    const record = await _dataProvider.getOne(resource, params);
    await loadFiles(record.data);
    return record;
  },
  getMetaData: async (resource, params) => {
    const data = await _dataProvider.getMetaData(resource, params);
    return data;
  },
};

export default dataProvider;
