import { Resource } from 'react-admin';
import SeasonFunding from './SeasonFunding';
import BrandFundingIcon from '../../assets/svgs/BrandFundingIcon';

const FundingRoutes = [
  <Resource
    key={'funding'}
    name='funding'
    icon={BrandFundingIcon}
    list={SeasonFunding} // List page will be loaded by default
    options={{ label: 'Season Funding', isMenuParent: true }}
  />,
];
export default FundingRoutes;
