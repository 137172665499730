import { NotificationTypesList } from './list';
import { NotificationTypesShow } from './show';
import { NotificationTypeEdit } from './edit';
import { NotificationTypesCreate } from './create';
import NotificationTypesIcon from '@material-ui/icons/NotificationImportant';

export default {
  name: 'notification_types',
  options: { label: 'NotificationTypes', menuParent: 'notifications' },
  icon: NotificationTypesIcon,
  list: NotificationTypesList,
  show: NotificationTypesShow,
  create: NotificationTypesCreate,
  edit: NotificationTypeEdit,
};
