import DataGridList from 'components/containers/List';
import ListActions from 'components/ListAction/ListActions';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import { List, TextField } from 'react-admin';
import CitiesFilter from './filter';

export const CitiesList = (props) => (
  <List
    title='Cities'
    filters={<CitiesFilter />}
    pagination={<Paginator />}
    perPage={15}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
    empty={<EmptyPage create />}
    actions={<ListActions listExporterType='Cities' />}
  >
    <DataGridList show edit>
      <TextField source='name' />
    </DataGridList>
  </List>
);
