import React from 'react';
import { EditTitle } from '../../../components/AppBar/Titles';
import { EditToolbar } from '../../../components/Toolbar/EditToolbar';
import { Edit, SimpleForm, TextInput } from 'react-admin';

export const ClientActionEdit = (props) => (
  <Edit title={<EditTitle source='name' />} {...props}>
    <SimpleForm variant='standard' margin='normal' toolbar={<EditToolbar />}>
      <TextInput source='name' label='Name' fullWidth />
      <TextInput
        source='required_linkable_type'
        label='Required Linkable Type'
        fullWidth
      />
    </SimpleForm>
  </Edit>
);
