import React from 'react';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
} from 'react-admin';

export const CountriesCreate = (props) => (
  <Create {...props} title='Create Country'>
    <SimpleForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
      <TextInput source='iso' validate={required()} fullWidth />
      <TextInput source='code' validate={required()} fullWidth />
      <TextInput source='name' validate={required()} fullWidth />
      <SelectInput
        validate={required()}
        source='is_blocked'
        label='Is Blocked?'
        choices={[
          { id: true, name: 'Yes' },
          { id: false, name: 'No' },
        ]}
      />
    </SimpleForm>
  </Create>
);
