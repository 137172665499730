import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import arrayMutators from 'final-form-arrays';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { Form, FormSpy } from 'react-final-form';
import FilterFormInput from './FilterFormInput';

const useStyles = makeStyles(
  (theme) => ({
    form: {
      marginTop: -theme.spacing(2),
      paddingTop: 0,
      display: 'flex',
      alignItems: 'flex-end',
      flexWrap: 'wrap',
      minHeight: theme.spacing(10),
      pointerEvents: 'none',
    },
    clearFix: { clear: 'right' },
  }),
  { name: 'RaFilterForm' },
);

const sanitizeRestProps = ({ ...props }) => props;

export const FilterForm = ({
  classes = {},
  className,
  resource,
  margin,
  variant,
  filters,
  displayedFilters = {},
  hideFilter,
  initialValues,
  ...rest
}) => {
  useEffect(() => {
    filters.forEach((filter) => {
      if (filter.props.alwaysOn && filter.props.defaultValue) {
        throw new Error(
          'Cannot use alwaysOn and defaultValue on a filter input. Please set the filterDefaultValues props on the <List> element instead.',
        );
      }
    });
  }, [filters]);

  const getShownFilters = () =>
    filters.filter(
      (filterElement) =>
        filterElement.props.alwaysOn ||
        displayedFilters[filterElement.props.source] ||
        typeof lodashGet(initialValues, filterElement.props.source) !==
          'undefined',
    );

  const handleHide = useCallback(
    (event) => hideFilter(event.currentTarget.dataset.key),
    [hideFilter],
  );

  return (
    <form
      className={classnames(className, classes.form)}
      {...sanitizeRestProps(rest)}
      onSubmit={handleSubmit}
    >
      {getShownFilters().map((filterElement) => (
        <FilterFormInput
          key={filterElement.props.source}
          filterElement={filterElement}
          handleHide={handleHide}
          resource={resource}
          margin={margin}
          variant={variant}
        />
      ))}
      <div className={classes.clearFix} />
    </form>
  );
};

const handleSubmit = (event) => {
  event.preventDefault();
  return false;
};

FilterForm.propTypes = {
  resource: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.node).isRequired,
  displayedFilters: PropTypes.object,
  hideFilter: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  classes: PropTypes.object,
  className: PropTypes.string,
};

export const mergeInitialValuesWithDefaultValues = (args) => {
  const initialValues = args.initialValues;
  const filters = args.filters;
  return {
    ...filters
      .filter(
        (filterElement) =>
          filterElement.props.alwaysOn && filterElement.props.defaultValue,
      )
      .reduce(
        (acc, filterElement) =>
          lodashSet(
            { ...acc },
            filterElement.props.source,
            filterElement.props.defaultValue,
          ),
        {},
      ),
    ...initialValues,
  };
};

const EnhancedFilterForm = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { classes: classesOverride, ...rest } = props;
  const classes = useStyles(props);

  const mergedInitialValuesWithDefaultValues =
    mergeInitialValuesWithDefaultValues(props);

  // eslint-disable-next-line no-unused-vars
  const { initialValues, ...rest2 } = rest;

  return (
    <Form
      onSubmit={handleFinalFormSubmit}
      initialValues={mergedInitialValuesWithDefaultValues}
      mutators={{ ...arrayMutators }}
      render={(formProps) => (
        <>
          <FormSpy
            subscription={FormSpySubscription}
            onChange={({ pristine, values }) => {
              if (pristine) {
                return;
              }
              rest?.setFilters(values);
            }}
          />
          <FilterForm classes={classes} {...formProps} {...rest2} />
        </>
      )}
    />
  );
};

const handleFinalFormSubmit = () => null;

// Options to instruct the FormSpy that it should only listen to the values and pristine changes
const FormSpySubscription = { values: true, pristine: true };

export default EnhancedFilterForm;
