import { Box } from '@material-ui/core';
import BoldText from 'components/common/BoldText';
import RoundedBorderSection from 'components/common/RoundedBorderSection';
import AddressSection from './AddressSection';

const getItemsList = (acceptedTokens) => [
  'You can only use the following tokens to fund the rewards pool: ' +
    acceptedTokens.join(', '),
  'Any funds sent to this address prior will fund the upcoming season, not the current season',
  'Ensure you are sending over the Polygon network',
  'Ensure that the proper token approvals have been set-up for your address',
  'Ensure you send the tokens to the following address',
];

const SummarySection = (props) => {
  const { rewardsPoolAddress, blockExplorerUrl, acceptedTokens } = props;

  return (
    <RoundedBorderSection color={'#5C5C5C'}>
      <BoldText>Please be aware</BoldText>
      <Box
        component={'ul'}
        sx={{
          lineHeight: '22px',
        }}
      >
        {getItemsList(acceptedTokens).map((item) => (
          <li key={item}>{item}</li>
        ))}
      </Box>
      <AddressSection
        title='VillageDAO Rewards Pool Address'
        address={rewardsPoolAddress}
        blockExplorerUrl={blockExplorerUrl}
      />
    </RoundedBorderSection>
  );
};

export default SummarySection;
