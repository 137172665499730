import React from 'react';
import Filter from 'components/Filter/Filter';
import { makeStyles } from '@material-ui/core/styles';
import {
  DateInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin';
import {
  EmailField,
  PhoneField,
  SearchField,
  DebouncedTextField,
  RangeFilter,
} from 'components/Fields';
const useStyles = makeStyles({
  selectInput: {
    width: '225px',
  },
});

const UsersFilter = (props) => {
  const classes = useStyles();

  return (
    <Filter {...props} variant='standard' margin='normal'>
      <SearchField source='q' alwaysOn />

      <DebouncedTextField label='Display Name' source='nickname' />

      <PhoneField label='Mobile Number' source='phone_number' />

      <EmailField label='Email' source='email' />

      <DebouncedTextField source='source_id' label='Source ID' />

      <ReferenceInput
        source='coupon_id'
        reference='coupons'
        label='Coupon'
        filterToQuery={(searchText) =>
          searchText
            ? {
                name: searchText,
                id: parseInt(searchText),
                deep_link: searchText,
                _autocomplete: true,
              }
            : ''
        }
      >
        <AutocompleteInput
          matchSuggestion={() => true}
          optionText={(record) =>
            record.name && record.id
              ? `${record.name} (${record.id}) - ${record.deep_link}`
              : ''
          }
        />
      </ReferenceInput>
      <SelectInput
        className={classes.selectInput}
        source='source_auto_assigned'
        label='Is Source Auto Assigned?'
        choices={[
          { id: 'true', name: 'Yes' },
          { id: 'false', name: 'No' },
        ]}
      />
      <RangeFilter label='Created At' source='created_at'>
        <DateInput label='Created At (from)' source='min_created_at' />
        <DateInput label='Created At (to)' source='max_created_at' />
      </RangeFilter>

      <RangeFilter label='Updated At' source='updated_at'>
        <DateInput label='Updated At (from)' source='min_updated_at' />
        <DateInput label='Updated At (to)' source='max_updated_at' />
      </RangeFilter>
    </Filter>
  );
};

export default UsersFilter;
