import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { stringify } from 'query-string';
import * as React from 'react';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';

export const CloneButton = ({
  label = 'Clone',
  userID,
  icon = defaultIcon,
  ...rest
}) => {
  return (
    <Button
      component={Link}
      to={
        userID
          ? {
              pathname: `/user_sources/create`,
              search: stringify({
                id: userID,
              }),
            }
          : `/users_sources/create`
      }
      label={label}
      onClick={stopPropagation}
      {...rest}
    >
      {icon}
    </Button>
  );
};

const defaultIcon = <AddIcon />;

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e) => e.stopPropagation();

// const omitId = ({ id, ...rest }: Record) => res

CloneButton.propTypes = {
  basePath: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  record: PropTypes.any,
  overrides: PropTypes.any,
  userID: PropTypes.number,
};

export default memo(CloneButton);
