import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  gutterBottom: {
    marginBottom: '30px',
  },
});

const PageTitle = (props) => {
  const classes = useStyles();
  return (
    <Typography variant='h5' component='h2' className={classes.gutterBottom}>
      {props.children}
    </Typography>
  );
};

export default PageTitle;
