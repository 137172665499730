import React from 'react';
import { SelectInput } from 'react-admin';
import FilterContainer from 'components/containers/Filter';
import { DebouncedTextField } from 'components/Fields';

const BrandingsFilter = (props) => (
  <FilterContainer {...props} variant='standard' margin='normal'>
    <DebouncedTextField label='Brand Id' source='brand_id' />
    <DebouncedTextField label='Expert Id' source='expert_id' />
    <SelectInput
      source='status'
      label='Status'
      choices={[
        { id: 'active', name: 'Active' },
        { id: 'suspended', name: 'Suspended' },
        { id: 'banned', name: 'banned' },
      ]}
    />
  </FilterContainer>
);

export default BrandingsFilter;
