import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  ReferenceField,
  FunctionField,
} from 'react-admin';
import { ViewTitle } from '../../../components/AppBar/Titles';

export const TagShow = (props) => (
  <Show title={<ViewTitle source='name' />} {...props}>
    <SimpleShowLayout>
      <TextField source='id' label='ID' />
      <TextField source='name' />
      <ReferenceField
        source='owner_id'
        reference='brands'
        label='Owner'
        link={(record, reference) => `/${reference}/${record.owner_id}/show`}
      >
        <FunctionField render={(record) => <>{record.name}</>} />
      </ReferenceField>
      <BooleanField source='is_elevation' label='Elevation' />
    </SimpleShowLayout>
  </Show>
);
