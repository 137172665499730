import React from 'react';
import ExpertIcon from '@material-ui/icons/StarBorderRounded';
import TimeZoneIcon from '@material-ui/icons/SnoozeRounded';
import AdminUserIcon from '@material-ui/icons/SupervisedUserCircleRounded';
import AdminRoleIcon from '@material-ui/icons/AssignmentInd';
import CategoryIcon from '@material-ui/icons/CategoryRounded';
import RevSharingSettingsIcon from '@material-ui/icons/AttachMoney';
import MembershipIcon from '@material-ui/icons/CardMembershipRounded';
import ProductsIcon from '@material-ui/icons/AssignmentTurnedIn';
import HubsIcon from '@material-ui/icons/GroupWorkRounded';
import LanguageIcon from '@material-ui/icons/TranslateRounded';
import SettingIcon from '@material-ui/icons/TuneRounded';
import SkillIcon from '@material-ui/icons/FitnessCenterRounded';
import CountryIcon from '@material-ui/icons/FlagRounded';
import StateIcon from '@material-ui/icons/MapRounded';
import CityIcon from '@material-ui/icons/LocationCityRounded';
import FeedItemIcon from '@material-ui/icons/DynamicFeedOutlined';
import FeedIcon from '@material-ui/icons/AllInbox';
import UserIcon from '@material-ui/icons/AccountCircleRounded';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import TransactionsIcon from '@material-ui/icons/Payment';
import EventLogIcon from '@material-ui/icons/EventNote';
import CouponIcon from '@material-ui/icons/Money';
import MeetingsIcon from '@material-ui/icons/VoiceChat';
import ReviewIcon from '@material-ui/icons/RateReview';
import TemplateCategoryIcon from '@material-ui/icons/ChromeReaderMode';
import ContentIcon from '@material-ui/icons/TextFields';
import ArticleIcon from '@material-ui/icons/Description';
import ReportIcon from '@material-ui/icons/FileCopy';
import Page from '../../assets/svgs/Page';
import { Banner } from '../../assets/svgs/Banner';
import TrendingSearch from '../../assets/svgs/TrendingSearch';
import Accessories from '../../assets/svgs/Accessories';
import Testimonial from '../../assets/svgs/Testimonial';

export const AutoCompleteResources = [
  { icon: <ExpertIcon />, label: 'Experts' },
  { icon: <TimeZoneIcon />, label: 'Time Zones' },
  { icon: <HubsIcon />, label: 'Hubs' },
  { icon: <CategoryIcon />, label: 'Categories' },
  { icon: <RevSharingSettingsIcon />, label: 'Rev. Share Model' },
  { icon: <MembershipIcon />, label: 'Memberships' },
  { icon: <ProductsIcon />, label: 'Products' },
  { icon: <MeetingsIcon />, label: 'Meetings' },
  { icon: <LanguageIcon />, label: 'Languages' },
  { icon: <SkillIcon />, label: 'Skills' },
  { icon: <TemplateCategoryIcon />, label: 'Template Categories' },
  { icon: <UserIcon />, label: 'Users' },
  { icon: <SubscriptionsIcon />, label: 'Subscriptions' },
  { icon: <TransactionsIcon />, label: 'Transactions' },
  { icon: <ReviewIcon />, label: 'Reviews' },
  { icon: <FeedIcon />, label: 'Collections' },
  { icon: <FeedItemIcon />, label: 'Feed Items' },
  { icon: <CountryIcon />, label: 'Countries' },
  { icon: <StateIcon />, label: 'States' },
  { icon: <CityIcon />, label: 'Cities' },
  { icon: <AdminUserIcon />, label: 'Admin Users' },
  { icon: <AdminRoleIcon />, label: 'Admin Roles' },
  { icon: <SettingIcon />, label: 'Settings' },
  { icon: <EventLogIcon />, label: 'Event Log' },
  { icon: <CouponIcon />, label: 'Coupons' },
  { icon: <ContentIcon />, label: 'Contents' },
  { icon: <ArticleIcon />, label: 'Articles' },
  { icon: <ReportIcon />, label: 'MAU Report' },
  { icon: <ReportIcon />, label: 'Rev. Share Report' },
  { icon: <Page />, label: 'Pages' },
  { icon: <Banner />, label: 'Banners' },
  { icon: <TrendingSearch />, label: 'Trending Search' },
  { icon: <Accessories />, label: 'Accessories' },
  { icon: <Testimonial />, label: 'Testimonial' },
  { icon: <ReportIcon />, label: 'MoM Report' },
  { icon: <ReportIcon />, label: 'Partner Stages' },
];
