export const TICKET_STATUS = {
  PENDING: 'pending',
  ASSIGNED: 'assigned',
  PENDING_CSAT: 'pending-csat',
  PENDING_SCORE: 'pending-score',
  FINALIZED: 'finalized',
  PENDING_DISPUTE: 'pending-dispute',
  SOLVED: 'solved',
};

export const TICKET_STATUS_LABELS = {
  PENDING: 'Pending',
  ASSIGNED: 'Assigned',
  PENDING_CSAT: 'Pending CSAT',
  PENDING_SCORE: 'Pending scores',
  FINALIZED: 'Finalized',
  PENDING_DISPUTE: 'Pending dispute',
  SOLVED: 'Solved',
};

export const TICKET_PRIORITIES = ['p1', 'p2', 'p3'];
