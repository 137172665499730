/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@material-ui/core';
import PageTitle from 'components/common/PageTitle';
import SeasonDetails from './components/SeasonDetails';
import SummarySection from './components/SummarySection';
import WarningSection from './components/WarningSection';
import { useDataProvider, useNotify, useUpdateLoading } from 'react-admin';
import { useEffect, useState } from 'react';
import useMetadata from 'utils/hooks/useMetadata';
import useSmartContract from 'utils/hooks/useSmartContract';
import useERC20 from 'utils/hooks/useERC20';
import * as REWARD_POOL_ABI from '@consensys/villagedao-smartcontract/abi/contracts/RewardPool.sol/RewardPool.json';
import moment from 'moment';
import { formatDateByUnit } from 'utils/dates';

const SeasonFunding = () => {
  const { startLoading, stopLoading } = useUpdateLoading();
  const dataProvider = useDataProvider();
  const metadata = useMetadata();
  const { smartContract: rewardsContract, isLoading: isSmartContractLoading } =
    useSmartContract(
      REWARD_POOL_ABI,
      metadata?.smartContracts?.RewardPool,
      metadata?.network?.rpcUrl,
    );
  const {
    getSymbol,
    getDecimals,
    isLoading: isLoadingERC20,
  } = useERC20(metadata?.network?.rpcUrl);
  const [isInitialized, setIsInitialized] = useState(false);
  const [brand, setBrand] = useState();
  const [currentSeason, setCurrentSeason] = useState();
  const [upcomingSeason, setUpcomingSeason] = useState();
  const [acceptedTokens, setAcceptedTokens] = useState([]);
  const notify = useNotify();

  /**
   * Get brand
   * TODO: Brand should be linked to Admin and not hardcoded
   */
  async function getBrand() {
    const { data } = await dataProvider.getList('brands', {});
    return data.find((s) => s.name === 'Metamask' || s.name === 'MetaMask');
  }

  async function getActiveSeason() {
    const { data } = await dataProvider.getList('seasons', {});
    return data.find((s) => s.status === 'active');
  }

  async function getTokensList() {
    const tokensList = await rewardsContract.getTokenList();
    return tokensList;
  }

  /**
   * Get token funding amount for a specific season
   */
  async function getTokenFunding(brandAddress, seasonID, tokenAddress) {
    let response = await rewardsContract.brandsFunding(
      brandAddress,
      seasonID,
      tokenAddress,
    );

    return response;
  }

  function hasSeasonNumber(season) {
    return season.number !== null && season.number !== undefined;
  }

  /**
   * Validates if the season has a number defined
   */
  function validateActiveSeason(season) {
    if (!season) {
      return;
    }
    if (season.id && !hasSeasonNumber(season)) {
      console.error(
        `The active season with the Id=${season.id} does not have a season number defined. Seasons details will not render`,
      );
      notify(
        `The active season does not have a season number defined. Please contact the administrator`,
        { type: 'error' },
      );
    }
  }

  useEffect(() => {
    async function initialize() {
      if (metadata && !isSmartContractLoading && !isLoadingERC20) {
        const brand = await getBrand();
        if (!brand) {
          throw new Error(
            `There are not brand available with the name Metamask or MetaMask. Please create one and try again`,
          );
        }
        const brandAddress = brand.public_id;
        const activeSeason = await getActiveSeason();
        const tokensList = await getTokensList();
        validateActiveSeason(activeSeason);

        const upcomingSeasonNumber = activeSeason ? activeSeason.number + 1 : 1;
        const currentSeasonFunding = [];
        const upcomingSeasonFunding = [];
        const tokenSymbols = [];

        // Get funding for each token
        for (const address of tokensList) {
          const symbol = await getSymbol(address);
          const decimals = await getDecimals(address);

          // If no symbol was returned we assume the address does not belong to a valid token
          if (!symbol) {
            continue;
          } else if (!tokenSymbols.includes(symbol)) {
            tokenSymbols.push(symbol);
          }

          if (activeSeason) {
            // Without season number we can't fetch funding
            if (!hasSeasonNumber(activeSeason)) {
              continue;
            }

            // Get current season funding
            const funding = await getTokenFunding(
              brandAddress,
              activeSeason.number,
              address,
            );

            // Check if it's a valid funding value
            if (funding > 0) {
              currentSeasonFunding.push({
                value: funding,
                symbol,
                decimals,
              });
            }
          }

          // Get upcoming season funding
          const upcomingFunding = await getTokenFunding(
            brandAddress,
            upcomingSeasonNumber,
            address,
          );

          // Check if it's a valid funding value
          if (upcomingFunding > 0) {
            upcomingSeasonFunding.push({
              value: upcomingFunding,
              symbol,
              decimals,
            });
          }
        }

        const seasonDuration = metadata.settings['season_duration_value'];
        const seasonDurationUnit = metadata.settings['season_duration_unit'];

        if (activeSeason) {
          setCurrentSeason({
            ...activeSeason,
            formattedStartDate: formatDateByUnit(
              activeSeason.starts_at,
              seasonDurationUnit,
            ),
            funding: currentSeasonFunding,
          });
        }

        setUpcomingSeason({
          number: upcomingSeasonNumber,
          formattedStartDate: activeSeason
            ? formatDateByUnit(
                moment(activeSeason.starts_at).add(
                  seasonDuration,
                  seasonDurationUnit,
                ),
                seasonDurationUnit,
              )
            : undefined,
          funding: upcomingSeasonFunding,
        });

        setBrand(brand);
        setAcceptedTokens(tokenSymbols);
        setIsInitialized(true);
        stopLoading();
      }
    }

    initialize().catch((error) =>
      console.error('Error trying to initialize', error),
    );
  }, [metadata, isSmartContractLoading, isLoadingERC20]);

  useEffect(() => {
    startLoading();
  }, []);

  /**
   * Renders Current and Upcoming seasons
   */
  const getSeasonsDetails = () => {
    // Do not render if we have a current season without a number
    if (currentSeason && !hasSeasonNumber(currentSeason)) {
      return;
    }

    return (
      <>
        {currentSeason && (
          <SeasonDetails title='Current' season={currentSeason} />
        )}
        <SeasonDetails title='Upcoming' season={upcomingSeason} />
      </>
    );
  };

  return (
    <>
      {isInitialized && (
        <Box sx={{ padding: 10 }}>
          <PageTitle>Season Funding</PageTitle>
          <Box
            sx={{
              display: 'grid',
              gridRowGap: 40,
            }}
          >
            <WarningSection brandNFTAddress={brand?.public_id}></WarningSection>
            <SummarySection
              blockExplorerUrl={metadata.network.blockExplorerUrl}
              rewardsPoolAddress={metadata.smartContracts.RewardPool}
              acceptedTokens={acceptedTokens}
            />
            {getSeasonsDetails()}
          </Box>
        </Box>
      )}
    </>
  );
};

export default SeasonFunding;
