import ExportDataButton from 'components/Buttons/ExportData';
import 'jspdf-autotable';
import React, { cloneElement } from 'react';
import {
  CreateButton,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
} from 'react-admin';

const ListActions = (props) => {
  const {
    className,
    listExporterType,
    filters,
    hideCreateButton,
    hideExportButton,
    ...rest
  } = props;
  const { resource, displayedFilters, filterValues, basePath, showFilter } =
    useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hideCreateButton ? '' : <CreateButton basePath={basePath} />}
      {!hideExportButton && <ExportDataButton type={listExporterType} />}
    </TopToolbar>
  );
};

export default ListActions;
