import FilterContainer from 'components/containers/Filter';
import React from 'react';
import { SelectInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import {
  TICKET_PRIORITIES,
  TICKET_STATUS,
  TICKET_STATUS_LABELS,
} from './constants';

const ConversationsFilter = (props) => (
  <FilterContainer {...props} variant='standard' margin='normal'>
    <ReferenceInput
      source='brand_id'
      reference='brands'
      label='Brand'
      perPage={25}
      filterToQuery={(searchText) => ({
        id: parseInt(searchText),
        name: searchText,
        _autocomplete: true,
      })}
    >
      <AutocompleteInput matchSuggestion={() => true} optionText='name' />
    </ReferenceInput>

    <ReferenceInput
      source='user_id'
      reference='users'
      label='User'
      perPage={25}
      filterToQuery={(searchText) => ({
        id: parseInt(searchText),
        name: searchText,
        _autocomplete: true,
      })}
    >
      <AutocompleteInput
        matchSuggestion={() => true}
        optionText={(choice) =>
          !choice?.id ? 'None' : choice?.name && `${choice.name} (${choice.id})`
        }
      />
    </ReferenceInput>

    <ReferenceInput
      source='season_id'
      reference='seasons'
      label='Season'
      perPage={25}
      filterToQuery={(searchText) => ({
        id: parseInt(searchText),
        name: searchText,
        _autocomplete: true,
      })}
    >
      <AutocompleteInput
        matchSuggestion={() => true}
        optionText={
          (choice) =>
            !choice?.id
              ? 'None'
              : choice.number || choice.number === 0
              ? `${choice.number}`
              : `Id=${choice.id}` // Handle undefined numbers
        }
      />
    </ReferenceInput>

    <SelectInput
      label='Status'
      source='status'
      fullWidth
      choices={Object.keys(TICKET_STATUS).map((k) => {
        return {
          id: TICKET_STATUS[k],
          name: TICKET_STATUS_LABELS[k],
        };
      })}
    />

    <SelectInput
      label='Priority'
      source='priority'
      fullWidth
      choices={TICKET_PRIORITIES.map((priority) => {
        return {
          id: priority,
          name: priority,
        };
      })}
    />
  </FilterContainer>
);

export default ConversationsFilter;
