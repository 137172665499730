import { DateField } from 'components/Fields';
import { Datagrid, TextField, EditButton, DeleteButton } from 'react-admin';
import { ShowButton } from 'components/Buttons';
import GenerateTempCredentials from 'components/Buttons/GenerateTempCredentials/index';
import React from 'react';

const DataGridList = ({
  disableDates,
  expandable,
  show,
  edit,
  destroy,
  generatePanelExpander,
  generateTempCredentials,
  children,
  resource,
  showId = true,
}) => {
  return (
    <Datagrid
      id={`${resource}-table`}
      expand={expandable ? generatePanelExpander() : null}
    >
      {showId && <TextField source='id' label='ID' />}
      {children}
      {disableDates ? null : (
        <DateField label='Created At' source='created_at' />
      )}
      {disableDates ? null : (
        <DateField label='Updated At' source='updated_at' />
      )}
      {edit && <EditButton />}
      {show && <ShowButton />}
      {destroy && <DeleteButton undoable={false} />}
      {generateTempCredentials && <GenerateTempCredentials />}
    </Datagrid>
  );
};
export default DataGridList;
