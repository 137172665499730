import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { sanitizeListRestProps, useListContext } from 'ra-core';

import ClearIcon from '@material-ui/icons/Clear';

import { RangeFilter } from 'components/Fields';

import FilterForm from './FilterForm';
import FilterButton from './FilterButton';

const useStyles = makeStyles(
  {
    button: {},
    form: {},
    resetButton: {
      position: 'absolute',
      left: '0',
      padding: '0px',
      fontSize: '12px',
    },

    resetButtonSpan: {
      color: '#248ad3',
    },

    resetButtonIcon: {
      color: '#248ad3',
      padding: '0',
      marginTop: '-3px',
      marginRight: '-6px',
    },
  },
  { name: 'RaFilter' },
);

const Filter = (props) => {
  const classes = useStyles(props);
  const {
    resource,
    showFilter,
    hideFilter,
    setFilters,
    displayedFilters,
    filterValues,
  } = useListContext(props);

  const [showResetButton, setShowResetButton] = useState(false);

  useEffect(() => {}, [showResetButton]);

  const renderButton = () => {
    // eslint-disable-next-line no-unused-vars
    const { classes: classesOverride, children, ...rest } = props;

    const resetFiltersHandler = () => {
      setShowResetButton(true);
    };

    return (
      <FilterButton
        className={classes.button}
        resource={resource}
        filters={React.Children.toArray(children)}
        showFilter={showFilter}
        displayedFilters={displayedFilters}
        filterValues={filterValues}
        onReset={resetFiltersHandler}
        {...sanitizeListRestProps(rest)}
      />
    );
  };

  const renderForm = () => {
    // eslint-disable-next-line no-unused-vars
    const { classes: classesOverride, children, ...rest } = props;

    const _hideFilter = (filterName) => {
      const filter = childArray.find(
        (_filter) => _filter.props.source === filterName,
      );

      if (filter.type === RangeFilter) {
        hideFilter(`min_${filterName}`);
        filterValues[`min_${filterName}`] = null;
        displayedFilters[`min_${filterName}`] = null;

        hideFilter(`max_${filterName}`);
        filterValues[`max_${filterName}`] = null;
        displayedFilters[`min_${filterName}`] = null;
      }

      hideFilter(filterName);
    };

    const resetFiltersHandler = () => {
      for (const [filterName] of Object.entries(displayedFilters)) {
        // eslint-disable-line
        _hideFilter(filterName);
        displayedFilters[`${filterName}`] = null;
        filterValues[`${filterName}`] = null;
      }
    };

    const childArray = React.Children.toArray(children);

    return (
      <>
        <FilterForm
          className={classes.form}
          resource={resource}
          filters={childArray}
          hideFilter={_hideFilter}
          displayedFilters={displayedFilters}
          initialValues={filterValues}
          setFilters={setFilters}
          {...sanitizeListRestProps(rest)}
        />

        {Object.keys(displayedFilters || {}).length ? (
          <Button
            style={{ marginTop: resource === 'MAU' ? '-18px' : '' }}
            className={classes.resetButton}
            onClick={resetFiltersHandler}
            startIcon={<ClearIcon className={classes.resetButtonIcon} />} // eslint-disable-line
          >
            <span className={classes.resetButtonSpan}>Clear Filters</span>
          </Button>
        ) : null}
      </>
    );
  };

  return props.context === 'button' ? renderButton() : renderForm();
};

Filter.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  context: PropTypes.any,
};

export default Filter;
