import React from 'react';
import { AutocompleteInput, ReferenceInput } from 'react-admin';
import Filter from 'components/Filter/Filter';

const ExpertTagsFilter = (props) => (
  <Filter {...props} variant='standard' margin='normal'>
    <ReferenceInput
      source='brand_uuid'
      reference='brands'
      label='Brand'
      perPage={25}
      filterToQuery={(searchText) => ({
        name: searchText,
        _autocomplete: true,
      })}
    >
      <AutocompleteInput
        matchSuggestion={() => true}
        optionValue='uuid'
        optionText='name'
      />
    </ReferenceInput>
    <ReferenceInput
      source='expert_uuid'
      reference='experts'
      label='Expert'
      perPage={25}
      filterToQuery={(searchText) => ({
        nickname: searchText,
        _autocomplete: true,
      })}
    >
      <AutocompleteInput
        optionValue='uuid'
        matchSuggestion={(_, choice) => {
          return choice.nickname;
        }}
        optionText={(choice) =>
          choice?.nickname ? choice.nickname : `UUID: (${choice?.uuid})`
        }
      />
    </ReferenceInput>
    <ReferenceInput
      source='tag_uuid'
      reference='tags'
      label='Tag'
      perPage={25}
      filterToQuery={(searchText) => ({
        nickname: searchText,
        _autocomplete: true,
      })}
    >
      <AutocompleteInput
        optionValue='uuid'
        matchSuggestion={() => true}
        optionText='name'
      />
    </ReferenceInput>
  </Filter>
);

export default ExpertTagsFilter;
