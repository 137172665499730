import React from 'react';
import { EditTitle } from '../../../components/AppBar/Titles';
import { EditToolbar } from '../../../components/Toolbar/EditToolbar';
import {
  Edit,
  SimpleForm,
  FileInput,
  ImageField,
  TextInput,
  BooleanInput,
} from 'react-admin';
import { FileUploaderPlaceholder } from '../../../components/ImageContainer/FilePlaceholder/FileUploaderPlaceholder';

export const NotificationTypeEdit = (props) => (
  <Edit title={<EditTitle source='name' />} {...props}>
    <SimpleForm variant='standard' margin='normal' toolbar={<EditToolbar />}>
      <TextInput source='title_pattern' label='Title Pattern' fullWidth />
      <TextInput source='body_pattern' label='Body Pattern' fullWidth />
      <BooleanInput
        source='use_sender_thumbnail'
        label="Use sender's thumbnail?"
        fullWidth
      />
      <BooleanInput
        source='send_push'
        label='Send push notifications?'
        fullWidth
      />
      <BooleanInput
        source='send_sms'
        label='Send SMS notifications?'
        fullWidth
      />

      <FileInput
        source='thumbnail_file'
        accept='image/*'
        label='Thumbnail'
        placeholder={<FileUploaderPlaceholder />}
        fullWidth
      >
        <ImageField source='src' />
      </FileInput>
    </SimpleForm>
  </Edit>
);
