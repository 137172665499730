import React from 'react';
import { ViewTitle } from '../../../components/AppBar/Titles';
import { Show, SimpleShowLayout, TextField, BooleanField } from 'react-admin';
import { DateFieldShow } from 'components/Fields';

export const SettingsShow = (props) => (
  <Show title={<ViewTitle source='name' />} {...props}>
    <SimpleShowLayout>
      <TextField source='id' label='ID' />
      <TextField source='key' label='Key' />
      <TextField source='value' label='Value' />
      <TextField source='description' label='Description' />
      <BooleanField source='is_client_accessible' label='Client Accessible?' />
      <DateFieldShow label='Created At' source='created_at' />
      <DateFieldShow label='Updated At' source='updated_at' />
    </SimpleShowLayout>
  </Show>
);
