import React from 'react';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';
import {
  Create,
  TabbedForm,
  TextInput,
  required,
  SelectInput,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import { FileUploaderPlaceholder } from '../../../components/ImageContainer/FilePlaceholder/FileUploaderPlaceholder';
import { ColorInput } from 'react-admin-color-input';
import { Box } from '@material-ui/core';
import { ImageUploaderWithPreview } from 'components/common/ImageUploaderWithPreview';
import { PopupPalette } from './components/PopupPalette';

export const BrandsCreate = (props) => (
  <Create {...props} title='Create Brand'>
    <TabbedForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
      <FormTab label='General'>
        <TextInput source='name' validate={required()} fullWidth />
        <TextInput
          source='public_id'
          label='Wallet Address'
          validate={required()}
          fullWidth
        />
        <TextInput source='site_url' label='site URL' fullWidth />
        <TextInput source='email' validate={required()} fullWidth />
        <TextInput source='description' multiline fullWidth />
        <SelectInput
          fullWidth
          defaultValue={false} // Will default to false but MFA should be removed since it's not properly used
          source='is_mfa_enabled'
          label='Multi-factor authentication'
          choices={[
            { id: true, name: 'enable' },
            { id: false, name: 'disable' },
          ]}
        />
        <ImageUploaderWithPreview
          source='thumbnail'
          accept='image/*'
          label='Thumbnail'
          placeholder={<FileUploaderPlaceholder />}
        />
      </FormTab>
      <FormTab label='Customizations'>
        <ColorInput label='Background color' source='backgroundColor' />
        <Box sx={{ width: '100%', minHeight: '30px', fontSize: '11px' }}>
          Should be saved in HEX format. Eg: #2d4257
        </Box>
        <ImageUploaderWithPreview
          source='page_background'
          accept='image/*'
          label='Page background image'
          helperText='Recommended size: 1920x1080'
        />
        <ImageUploaderWithPreview
          source='header_background'
          accept='image/*'
          label='Header background image'
          helperText='Header height: 120px. Recommended size: 1920x120px'
        />
      </FormTab>
      <FormTab label='Popup'>
        <ArrayInput label='Allowed Domains' source='allowed_domains' fullWidth>
          <SimpleFormIterator disableRemove disableReordering>
            <TextInput label='Domain' validate={[required()]} fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
        <ImageUploaderWithPreview
          source='popup_banner'
          accept='image/*'
          label='Popup banner image'
          helperText='Container height: 220px. Dimensions on desktop: 390x220px. (on mobile width will depend on device)'
        />

        <PopupPalette />
      </FormTab>
    </TabbedForm>
  </Create>
);
