import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  filter: {
    marginBottom: '-18px',
    '& > *': {
      marginRight: '6px',
    },
  },
});

export const RangeFilter = (props) => {
  const classes = useStyles();

  return (
    <div label={props.label} className={classes.filter}>
      {props.children}
    </div>
  );
};
