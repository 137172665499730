/**
 * Checks if email is a wallet email. Eg: 0x0@wallet.sherpa
 */
export const isWalletEmail = (user) => user?.email?.includes('@wallet');

/**
 * Retrieves wallet address from user email
 */
export const getUserWallet = (user) => user?.email?.split('@')[0];

/**
 * Retrieves email or wallet address from user
 */
export const getEmailOrWallet = (user, truncateWallet = false) => {
  if (isWalletEmail(user)) {
    const wallet = getUserWallet(user);

    return truncateWallet
      ? wallet?.substring(0, 10) + '...' + wallet?.substring(38, 42)
      : wallet;
  }
  return user?.email;
};
