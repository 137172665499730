import SystemIcon from '@material-ui/icons/Settings';
import { Resource } from 'react-admin';
import adminUserViews from './Accounts';
import settingViews from './Settings';
import triggerViews from './Triggers';

const SystemRoutes = [
  <Resource
    key='system'
    name='system'
    icon={SystemIcon}
    options={{ label: 'System', isMenuParent: true }}
  />,
  <Resource key='admin-views' {...adminUserViews} />,
  <Resource key='settings-views' {...settingViews} />,
  <Resource key='trigger-views' {...triggerViews} />,
];
export default SystemRoutes;
