import FilterContainer from 'components/containers/Filter';
import { DebouncedTextField, EmailField } from 'components/Fields';
import React from 'react';
const AdminUsersFilter = (props) => (
  <FilterContainer {...props} variant='standard' margin='normal'>
    <DebouncedTextField label='First Name' source='first_name' />

    <DebouncedTextField label='Last Name' source='last_name' />

    <EmailField source='email' />
  </FilterContainer>
);

export default AdminUsersFilter;
