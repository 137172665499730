import React from 'react';
import { EditTitle } from '../../../components/AppBar/Titles';
import { EditToolbar } from '../../../components/Toolbar/EditToolbar';
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  number,
  FormDataConsumer,
  SelectInput,
} from 'react-admin';
import { useForm } from 'react-final-form';

const SelectOwnerType = () => {
  const form = useForm();
  /*   const filterAssignment = (searchText) => {
    if (form?.getFieldState('reviewable_type')?.value === 'expert') {
      return {
        '$expert.id$': parseInt(searchText),
        '$expert.nickname$': searchText,
        _autocomplete: true,
      };
    } else {
      return {
        id: parseInt(searchText),
        name: searchText,
        _autocomplete: true,
      };
    }
  }; */
  return (
    <>
      <SelectInput
        source='reviewable_type'
        onChange={() => {
          form.change('reviewable_id', null);
        }}
        choices={[{ id: 'expert', name: 'Expert' }]}
      />
      <FormDataConsumer>
        {() => (
          <ReferenceInput
            validate={required()}
            source='reviewable_id'
            reference='experts'
            label='Expert'
            perPage={25}
            filterToQuery={(searchText) => ({
              id: parseInt(searchText),
              nickname: searchText,
              _autocomplete: true,
            })}
            sort={{ field: 'id', order: 'ASC' }}
            fullWidth
          >
            <AutocompleteInput
              matchSuggestion={() => true}
              optionText={(choice) =>
                choice?.id ? `${choice.name} (${choice.id})` : 'None'
              }
            />
          </ReferenceInput>
        )}
      </FormDataConsumer>
    </>
  );
};
export const ReviewsEdit = (props) => (
  <Edit title={<EditTitle source='id' />} {...props}>
    <SimpleForm
      variant='standard'
      margin='normal'
      toolbar={<EditToolbar hideDelete={true} />}
    >
      <SelectOwnerType />

      <SelectInput
        source='reviewer_type'
        choices={[{ id: 'user', name: 'User' }]}
        defaultValue='user'
      />

      <ReferenceInput
        source='reviewer_id'
        reference='users'
        label='User'
        validate={required()}
        allowEmpty
        perPage={25}
        filterToQuery={(searchText) =>
          searchText
            ? {
                id: parseInt(searchText),
                first_name: searchText,
                last_name: searchText,
                _autocomplete: true,
              }
            : ''
        }
        fullWidth
      >
        <AutocompleteInput
          matchSuggestion={() => true}
          optionText={(self) =>
            self && `${self.first_name} ${self.last_name} (${self.id})`
          }
        />
      </ReferenceInput>

      <NumberInput
        label='Rating'
        source='rating'
        min={0}
        max={5}
        step={0.5}
        validate={[number(), required()]}
        fullWidth
      />
      <SelectInput
        source='status'
        choices={[
          { id: 'pending-approval', name: 'Pending' },
          { id: 'approved', name: 'Approved' },
          { id: 'rejected', name: 'Rejected' },
        ]}
      />
      <TextInput source='comment' validate={required()} multiline fullWidth />
    </SimpleForm>
  </Edit>
);
