import React, { useState } from 'react';
import { TextField, Chip } from '@material-ui/core';
import { Button, useInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
const useStyle = makeStyles((theme) => ({
  disabledButton: {
    color: theme.palette.action.disabled,
  },
  chipContainer: {
    marginTop: 15,
  },
  chipMargin: {
    margin: '0 5px 5px 0',
  },
}));
export const ArrayStringInput = (props) => {
  const classes = useStyle();
  const { label } = props;
  const {
    input: { onChange, value: values },
  } = useInput(props);
  const [item, setItem] = useState('');
  const addValue = () => {
    onChange([...values, item]);
    setItem('');
  };
  const removeValue = (index) => () => {
    const tempValues = [...values];
    tempValues.splice(index, 1);
    onChange([...tempValues]);
  };
  return (
    <div>
      <TextField
        label={label}
        value={item}
        onChange={(e) => setItem(e.target.value)}
      />
      <Button
        onClick={addValue}
        disabled={!item}
        className={classnames({ [classes.disabledButton]: !item })}
        label='add'
        classes={{
          label: !item ? classes.disabledButton : null,
        }}
      />
      <div className={classes.chipContainer}>
        {values.map?.((value, index) => {
          return (
            <Chip
              className={classes.chipMargin}
              key={value}
              label={value}
              onDelete={removeValue(index)}
            />
          );
        })}
      </div>
    </div>
  );
};

export const ArrayStringField = ({ record, source }) => {
  const classes = useStyle();
  return (
    <>
      {record[source]?.map((item) => (
        <Chip label={item} key={item} className={classes.chipMargin} />
      ))}
    </>
  );
};
ArrayStringField.defaultProps = { addLabel: true };
