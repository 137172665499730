import UsersIcon from '@material-ui/icons/AccountCircleRounded';
import { Resource } from 'react-admin';
import usersRoute from './Users';

const UsersRoutes = [
  <Resource
    key='users'
    name='_users'
    icon={UsersIcon}
    options={{ label: 'Users', isMenuParent: true }}
  />,
  <Resource key='users-route' {...usersRoute} />,
];
export default UsersRoutes;
