import React, { useCallback, useEffect, useRef, isValidElement } from 'react';
import Downshift from 'downshift';
import classNames from 'classnames';
import { TextField, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useInput, FieldTitle, useSuggestions, warning } from 'ra-core';

import { InputHelperText } from './InputHelperText';
import { AutocompleteSuggestionList } from './AutocompleteSuggestionList';
import { AutocompleteSuggestionItem } from './AutocompleteSuggestionItem';

// Drag & Drop imports:
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import { useDataProvider, useMutation } from 'react-admin';

export const AutocompleteArrayInputUpdate = (props) => {
  const {
    allowDuplicates,
    allowEmpty,
    choices = [],
    disabled,
    emptyText,
    emptyValue,
    format,
    fullWidth,
    id: idOverride,
    input: inputOverride,
    isRequired: isRequiredOverride,
    label,
    limitChoicesToValue,
    margin = 'dense',
    matchSuggestion,
    meta: metaOverride,
    onBlur,
    onChange,
    onFocus,
    options: { suggestionsContainerProps, labelProps, ...options } = {},
    optionText = 'name',
    optionValue = 'id',
    parse,
    resource,
    setFilter,
    shouldRenderSuggestions: shouldRenderSuggestionsOverride,
    source,
    suggestionLimit,
    translateChoice = true,
    validate,
    variant = 'filled',
    ...rest
  } = props;

  warning(
    isValidElement(optionText) && !matchSuggestion,
    `If the optionText prop is a React element, you must also specify the matchSuggestion prop:
<AutocompleteArrayInputUpdate
    matchSuggestion={(filterValue, suggestion) => true}
/>
        `,
  );

  warning(
    source === undefined,
    `If you're not wrapping the AutocompleteArrayInputUpdate inside a ReferenceArrayInput, you must provide the source prop`,
  );

  warning(
    choices === undefined,
    `If you're not wrapping the AutocompleteArrayInputUpdate inside a ReferenceArrayInput, you must provide the choices prop`,
  );

  const classes = useStyles(props);
  const [mutate, { loading, data }] = useMutation();
  let inputEl = useRef();
  let anchorEl = useRef();

  const {
    id,
    input,
    isRequired,
    meta: { touched, error },
  } = useInput({
    format,
    id: idOverride,
    input: inputOverride,
    meta: metaOverride,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  });

  const dataProvider = useDataProvider();

  const [newChoicesBoolean, setNewChoicesBoolean] = React.useState(false);
  const [filterValue, setFilterValue] = React.useState('');

  const values = input.value || emptyArray;
  let newChoices = [];

  // we have to fetch the exact choices cause by default this autocomplete component
  // get it's data from the perPage choices which leads to undefined dnd items.
  const getNewChoices = useCallback(async () => {
    if (values.length && !newChoicesBoolean) {
      for (const key in values) {
        await dataProvider
          .getOne(resource, { id: values[key] })
          .then((response) => {
            newChoices.push(response.data);
          });
      }
      setNewChoicesBoolean(true);
    }
  }, [values, choices]); // eslint-disable-line

  // only when the values array has items inside, fire the fetch function:
  React.useEffect(() => {
    getNewChoices();
  }, [values.length]); // eslint-disable-line

  const [dragAndDropSelectedItems, setDragAndDropSelectedItems] =
    React.useState(newChoices);
  useEffect(() => {
    if (data && !loading) {
      const temp = [...dragAndDropSelectedItems];
      const id = temp.indexOf((item) => item.id === data.id);
      temp[id] = data;
      setDragAndDropSelectedItems(temp);
    }
  }, [data]);
  const approve = (id, index) =>
    mutate({
      type: 'update',
      resource,
      payload: {
        id,
        data: { order: index + 1 },
      },
    });
  const updateArray = (newArray) => {
    newArray.forEach((item, index) => {
      approve(item.id, index);
    });
  };
  // Drag & Drop sorting function:
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setDragAndDropSelectedItems((prevArray) => {
      const newArray = arrayMoveImmutable(prevArray, oldIndex, newIndex);
      updateArray(newArray);
      return newArray;
    });
  };

  const SortableItem = SortableElement(({ value }) => (
    <div className={classes.dragAndDropItem}>{value}</div>
  ));

  // if the user changed the items order by dnd, here we're updating the input as
  // well in order to change it in the form.
  React.useEffect(() => {
    if (dragAndDropSelectedItems.length) {
      input.onChange(dragAndDropSelectedItems.map(getChoiceValue));
    }
  }, [dragAndDropSelectedItems]); // eslint-disable-line

  React.useEffect(() => {
    if (!input.value) {
      setDragAndDropSelectedItems([]);
    }
  }, [input.value]);

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div
        className={classNames({
          [classes.chipContainerFilled]: variant === 'filled',
          [classes.chipContainerOutlined]: variant === 'outlined',
        })}
      >
        {items.map((value, index) => {
          const label = getChoiceText(value);
          return (
            <SortableItem
              key={`item-${label || index}`}
              index={index}
              value={
                <Chip
                  key={label}
                  tabIndex={-1}
                  label={label}
                  className={classes.chip}
                  onDelete={handleDelete(value)}
                />
              }
            />
          );
        })}
      </div>
    );
  });

  const { getChoiceText, getChoiceValue, getSuggestions } = useSuggestions({
    allowDuplicates,
    allowEmpty,
    choices,
    emptyText,
    emptyValue,
    limitChoicesToValue,
    matchSuggestion,
    optionText,
    optionValue,
    selectedItem: dragAndDropSelectedItems,
    suggestionLimit,
    translateChoice,
  });

  const handleFilterChange = useCallback(
    (eventOrValue) => {
      const event = eventOrValue;
      const value = event.target ? event.target.value : eventOrValue;

      setFilterValue(value);
      if (setFilter) {
        setFilter(value);
      }
    },
    [setFilter, setFilterValue],
  );

  // We must reset the filter every time the value changes to ensure we
  // display at least some choices even if the input has a value.
  // Otherwise, it would only display the currently selected one and the user
  // would have to first clear the input before seeing any other choices
  useEffect(() => {
    handleFilterChange('');
  }, [values.join(','), handleFilterChange]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleKeyDown = useCallback(
    (event) => {
      // Remove latest item from array when user hits backspace with no text
      if (
        dragAndDropSelectedItems?.length &&
        !filterValue.length &&
        event.key === 'Backspace'
      ) {
        const newSelectedItems = dragAndDropSelectedItems.slice(
          0,
          dragAndDropSelectedItems.length - 1,
        );
        input.onChange(newSelectedItems.map(getChoiceValue));
      }
    },
    [filterValue.length, getChoiceValue, input, dragAndDropSelectedItems],
  );

  const handleChange = useCallback(
    (item) => {
      let newSelectedItems =
        !allowDuplicates &&
        dragAndDropSelectedItems &&
        dragAndDropSelectedItems.includes(item)
          ? [...dragAndDropSelectedItems]
          : dragAndDropSelectedItems && [...dragAndDropSelectedItems, item];
      setFilterValue('');
      setDragAndDropSelectedItems(newSelectedItems);
      input.onChange(newSelectedItems.map(getChoiceValue));
    },
    [
      allowDuplicates,
      getChoiceValue,
      input,
      dragAndDropSelectedItems,
      setFilterValue,
    ],
  );

  const handleDelete = useCallback(
    (item) => () => {
      if (dragAndDropSelectedItems) {
        const newSelectedItems = [...dragAndDropSelectedItems];
        newSelectedItems.splice(newSelectedItems.indexOf(item), 1);
        setDragAndDropSelectedItems(newSelectedItems);
        input.onChange(newSelectedItems.map(getChoiceValue));
      }
    },
    [input, dragAndDropSelectedItems, getChoiceValue],
  );

  // This function ensures that the suggestion list stay aligned to the
  // input element even if it moves (because user scrolled for example)
  const updateAnchorEl = () => {
    if (!inputEl.current) {
      return;
    }

    const inputPosition = inputEl.current.getBoundingClientRect();

    // It works by implementing a mock element providing the only method used
    // by the PopOver component, getBoundingClientRect, which will return a
    // position based on the input position
    if (!anchorEl.current) {
      anchorEl.current = { getBoundingClientRect: () => inputPosition };
    } else {
      const anchorPosition = anchorEl.current.getBoundingClientRect();

      if (
        anchorPosition.x !== inputPosition.x ||
        anchorPosition.y !== inputPosition.y
      ) {
        anchorEl.current = {
          getBoundingClientRect: () => inputPosition,
        };
      }
    }
  };

  const storeInputRef = (input) => {
    inputEl.current = input;
    updateAnchorEl();
  };

  const handleBlur = useCallback(
    (event) => {
      setFilterValue('');
      handleFilterChange('');
      input.onBlur(event);
    },
    [handleFilterChange, input, setFilterValue],
  );

  const handleFocus = useCallback(
    (openMenu) => (event) => {
      openMenu(event);
      input.onFocus(event);
    },
    [input],
  );

  const handleClick = useCallback(
    (openMenu) => (event) => {
      // we're adding a disabled prop here for FeedCollection new feed validation
      if (event.target === inputEl.current && disabled) {
        openMenu(event);
      }
    },
    [], // eslint-disable-line
  );

  const shouldRenderSuggestions = (val) => {
    if (
      shouldRenderSuggestionsOverride !== undefined &&
      typeof shouldRenderSuggestionsOverride === 'function'
    ) {
      return shouldRenderSuggestionsOverride(val);
    }

    return true;
  };

  return (
    <Downshift
      inputValue={filterValue}
      onChange={handleChange}
      selectedItem={dragAndDropSelectedItems}
      itemToString={(item) => getChoiceValue(item)}
      {...rest}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        inputValue: suggestionFilter,
        highlightedIndex,
        openMenu,
      }) => {
        const isMenuOpen = isOpen && shouldRenderSuggestions(suggestionFilter);
        const { onBlur, onChange, onFocus, color, size, ...inputProps } =
          getInputProps({
            onBlur: handleBlur,
            onFocus: handleFocus(openMenu),
            onClick: handleClick(openMenu),
            onKeyDown: handleKeyDown,
          });
        return (
          <div className={classes.container}>
            <TextField
              id={id}
              fullWidth={fullWidth}
              InputProps={{
                inputRef: storeInputRef,
                classes: {
                  root: classNames(classes.inputRoot, {
                    [classes.inputRootFilled]: variant === 'filled',
                  }),
                  input: classes.inputInput,
                },
                startAdornment: (
                  <SortableList
                    items={newChoicesBoolean ? dragAndDropSelectedItems : []}
                    onSortEnd={onSortEnd}
                    lockAxis='y'
                    axis='y'
                    distance={1}
                  />
                ),
                onBlur,
                onChange: (event) => {
                  handleFilterChange(event);
                  onChange(event);
                },
                onFocus,
              }}
              error={!!(touched && error)}
              label={
                <FieldTitle
                  label={label}
                  {...labelProps}
                  source={source}
                  resource={resource}
                  isRequired={
                    typeof isRequiredOverride !== 'undefined'
                      ? isRequiredOverride
                      : isRequired
                  }
                />
              }
              InputLabelProps={getLabelProps({
                htmlFor: id,
              })}
              helperText={
                <InputHelperText
                  touched={touched}
                  error={error}
                  helperText='Drag & drop to reorder.'
                />
              }
              variant={variant}
              margin={margin}
              color={color}
              size={size}
              disabled={disabled}
              {...inputProps}
              {...options}
            />
            <AutocompleteSuggestionList
              isOpen={isMenuOpen}
              menuProps={getMenuProps(
                {},
                // https://github.com/downshift-js/downshift/issues/235
                { suppressRefError: true },
              )}
              inputEl={inputEl.current}
              suggestionsContainerProps={suggestionsContainerProps}
              className={classes.suggestionsContainer}
            >
              {getSuggestions(suggestionFilter).map((suggestion, index) => (
                <AutocompleteSuggestionItem
                  key={getChoiceValue(suggestion)}
                  suggestion={suggestion}
                  index={index}
                  highlightedIndex={highlightedIndex}
                  isSelected={() => {
                    return dragAndDropSelectedItems
                      ?.map(getChoiceValue)
                      ?.includes(getChoiceValue(suggestion));
                  }}
                  filterValue={filterValue}
                  getSuggestionText={getChoiceText}
                  {...getItemProps({
                    item: suggestion,
                  })}
                />
              ))}
            </AutocompleteSuggestionList>
          </div>
        );
      }}
    </Downshift>
  );
};

const emptyArray = [];

const useStyles = makeStyles(
  (theme) => {
    const chipBackgroundColor =
      theme.palette.type === 'light'
        ? 'rgba(0, 0, 0, 0.09)'
        : 'rgba(255, 255, 255, 0.09)';

    return {
      dragAndDropItem: {
        zIndex: 999,
      },
      container: {
        flexGrow: 1,
        position: 'relative',
      },
      suggestionsContainer: {},
      chip: {
        margin: theme.spacing(0.5, 0.5, 0.5, 0),
        cursor: 'grab',
        zIndex: 1000,
        '&:active': { cursor: 'grabbing' },
      },
      chipContainerFilled: {
        margin: '27px 12px 10px 0',
        '&:active': {
          cursor: 'grabbing',
        },
      },
      chipContainerOutlined: {
        margin: '12px 12px 10px 0',
        '&:active': {
          cursor: 'grabbing',
        },
      },
      inputRoot: {
        flexWrap: 'wrap',
      },
      inputRootFilled: {
        flexWrap: 'wrap',
        '& $chip': {
          backgroundColor: chipBackgroundColor,
        },
      },
      inputInput: {
        width: 'auto',
        flexGrow: 1,
      },
    };
  },
  { name: 'RaAutocompleteArrayInputUpdate' },
);
