import DataGridList from 'components/containers/List';
import ListActions from 'components/ListAction/ListActions';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import React from 'react';
import {
  List,
  BooleanField,
  TextField,
  ChipField,
  ReferenceField,
  FunctionField,
} from 'react-admin';
import { AvatarContainer, UserAvatar } from 'components/Avatars';
import { JsonField } from 'react-admin-json-view';
import TriggersFilter from './filter';

export const TriggersList = (props) => (
  <List
    title='Triggers'
    filters={<TriggersFilter />}
    pagination={<Paginator />}
    perPage={25}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
    empty={<EmptyPage create />}
    actions={<ListActions listExporterType='Triggers' />}
  >
    <DataGridList create>
      <ReferenceField
        source='owner_id'
        reference='users'
        label='Receiver'
        link={(record, reference) => `/${reference}/${record.owner_id}/show`}
      >
        <FunctionField
          render={(record) => (
            <AvatarContainer>
              <UserAvatar url={record?.thumbnail_url || null} />
              {record.nickname}
            </AvatarContainer>
          )}
        />
      </ReferenceField>
      <TextField source='type' label='Type' />
      <JsonField
        source='payload'
        addLabel={true}
        reactJsonOptions={{
          name: null,
          collapsed: true,
          enableClipboard: true,
          displayDataTypes: false,
        }}
      />
      <ChipField source='priority' variant='outlined' />
      <BooleanField source='is_active' label='Active?' />
    </DataGridList>
  </List>
);
