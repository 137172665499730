import React from 'react';
import { ChipField, Datagrid, EditButton, List, TextField } from 'react-admin';

import { DateField } from 'components/Fields';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import StatesFilter from './filter';
import ListActions from 'components/ListAction/ListActions';
import { Paginator } from 'components/Paginator/Paginator';

export const StatesList = (props) => (
  <List
    title='States'
    filters={<StatesFilter />}
    pagination={<Paginator />}
    perPage={15}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
    empty={<EmptyPage create />}
    actions={<ListActions listExporterType='States' />}
  >
    <Datagrid>
      <TextField source='id' label='ID' />
      <ChipField source='iso' label='ISO' />
      <TextField source='name' />
      <DateField label='Created At' source='created_at' />
      <EditButton />
    </Datagrid>
  </List>
);
