import { Resource } from 'react-admin';
import ExpertsIcon from '@material-ui/icons/StarBorderRounded';
import representativeViews from './Representative';

const RepresentativesRoutes = [
  <Resource
    key='representative'
    name='representative'
    icon={ExpertsIcon}
    options={{ label: 'Representatives', isMenuParent: true }}
  />,
  <Resource key='representative-view' {...representativeViews} />,
];
export default RepresentativesRoutes;
