import ReviewsIcon from '@material-ui/icons/RateReview';
import { ReviewsList } from './list';
import { ReviewsShow } from './show';
import { ReviewsEdit } from './edit';

export default {
  name: 'reviews',
  options: { label: 'Reviews', menuParent: 'expert' },
  icon: ReviewsIcon,
  list: ReviewsList,
  show: ReviewsShow,
  edit: ReviewsEdit,
};
