import { ImageField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => ({
  image: { maxHeight: '10rem', maxWidth: '100%', marginLeft: '0' },
}));

/**
 * Returns image with contained dimensions
 */
export const ContainedImage = (props) => {
  const classes = styles();
  return <ImageField classes={classes} {...props} />;
};
