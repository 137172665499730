import { Box } from '@material-ui/core';
import { ColorInput } from 'react-admin-color-input';

export const PopupPalette = (props) => {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          minHeight: '30px',
          fontSize: '18px',
        }}
      >
        Colors Palette
      </Box>
      <Box
        sx={{
          width: '100% !important;',
          fontSize: '12px',
        }}
      >
        The primary set of colors will be used for the primary buttons.
      </Box>
      <Box
        sx={{
          width: '100% !important;',
          minHeight: '30px',
          fontSize: '12px',
        }}
      >
        The secondary set of colors will be used for the secondary buttons,
        global backgrounds and text colors.
      </Box>

      <ColorInput
        label='Primary Background color'
        source='popup_palette.primaryBackgroundColor'
        {...props}
      />
      <ColorInput
        label='Primary Text color'
        source='popup_palette.primaryTextColor'
        {...props}
      />
      <ColorInput
        label='Secondary Background color'
        source='popup_palette.secondaryBackgroundColor'
        {...props}
      />
      <ColorInput
        label='Secondary Text color'
        source='popup_palette.secondaryTextColor'
        style={{ marginBottom: '20px' }}
        {...props}
      />
      <Box
        sx={{
          width: '100% !important;',
          fontSize: '12px',
          marginTop: '40px',
        }}
      >
        If text colors are not specified, they will default to black or white
        based on contrast with the main color.
      </Box>

      <Box
        sx={{
          width: '100% !important;',
          minHeight: '50px',
          fontSize: '12px',
        }}
      >
        All colors should be saved in HEX format. Eg: #2d4257
      </Box>
    </>
  );
};
