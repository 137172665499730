import React from 'react';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  FileInput,
  ImageField,
} from 'react-admin';
import { FileUploaderPlaceholder } from '../../../components/ImageContainer/FilePlaceholder/FileUploaderPlaceholder';

export const NotificationTypesCreate = (props) => (
  <Create {...props} title='Create NotificationTypes'>
    <SimpleForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
      <TextInput source='name' validate={required()} fullWidth />
      <TextInput source='title_pattern' label='Title Pattern' fullWidth />
      <TextInput source='body_pattern' label='Body Pattern' fullWidth />
      <BooleanInput
        source='use_sender_thumbnail'
        label="Use sender's thumbnail?"
        fullWidth
      />
      <BooleanInput
        source='send_push'
        label='Send push notifications?'
        fullWidth
      />
      <BooleanInput
        source='send_sms'
        label='Send SMS notifications?'
        fullWidth
      />

      <FileInput
        source='thumbnail_file'
        accept='image/*'
        label='Thumbnail'
        placeholder={<FileUploaderPlaceholder />}
        fullWidth
      >
        <ImageField source='src' />
      </FileInput>
    </SimpleForm>
  </Create>
);
