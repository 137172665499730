import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslate } from 'react-admin';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './LoginForm.module.css';
const Input = ({ meta: { touched, error }, input: inputProps, ...props }) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const formStyles = makeStyles(
  (theme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },

    loginButton: {
      width: '100%',
      marginTop: '30px',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'RaLoginForm' },
);

export const LoginForm = ({ onSubmit, loading, ...props }) => {
  const classes = formStyles(props);
  const [isVisible, setIsVisible] = useState(false);
  const translate = useTranslate();

  const validate = (values) => {
    const errors = { username: undefined, password: undefined };

    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }

    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }

    return errors;
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                autoFocus
                id='username'
                name='username'
                component={Input}
                label={translate('ra.auth.username')}
                disabled={loading}
              />
            </div>
            <div className={classes.input}>
              <Field
                id='password'
                name='password'
                component={Input}
                label={translate('ra.auth.password')}
                type={isVisible ? 'text' : 'password'}
                disabled={loading}
                autoComplete='current-password'
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      {isVisible ? (
                        <Tooltip title='Hide Password'>
                          <Visibility
                            onClick={() => setIsVisible(false)}
                            color='primary'
                            className={styles.icon_pointer}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title='Show Password'>
                          <VisibilityOff
                            onClick={() => setIsVisible(true)}
                            color='primary'
                            className={styles.icon_pointer}
                          />
                        </Tooltip>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <CardActions>
            <Button
              variant='contained'
              type='submit'
              color='primary'
              disabled={loading}
              className={classes.loginButton}
            >
              {loading && (
                <CircularProgress
                  className={classes.icon}
                  size={18}
                  thickness={2}
                />
              )}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
        </form>
      )}
    />
  );
};
