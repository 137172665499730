import { Box } from '@material-ui/core';

const BoldText = (props) => {
  return (
    <Box component={'span'} sx={{ fontWeight: 'bold' }}>
      {props.children}
    </Box>
  );
};

export default BoldText;
