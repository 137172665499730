import DataGridList from 'components/containers/List';
import ListActions from 'components/ListAction/ListActions';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import React from 'react';
import { List, ReferenceField, FunctionField, ChipField } from 'react-admin';
import ApplicationsFilter from './filter';
import { AvatarContainer, UserAvatar } from 'components/Avatars';

export const ApplicationsList = (props) => (
  <List
    title='Applications'
    filters={<ApplicationsFilter />}
    pagination={<Paginator />}
    perPage={25}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
    empty={<EmptyPage create />}
    actions={
      <ListActions listExporterType='Applications' hideCreateButton='true' />
    }
  >
    <DataGridList show edit destroy>
      <ReferenceField
        source='brand_id'
        reference='brands'
        label='Brand'
        link={(record, reference) => `/${reference}/${record.brand_id}/show`}
      >
        <FunctionField
          render={(record) => (
            <AvatarContainer>
              <UserAvatar url={record?.thumbnail_url || null} />
              {record.name}
            </AvatarContainer>
          )}
        />
      </ReferenceField>
      <ReferenceField
        source='expert_id'
        reference='experts'
        label='Expert'
        link={(record, reference) => `/${reference}/${record.expert_id}/show`}
      >
        <FunctionField
          render={(record) => (
            <AvatarContainer>
              <UserAvatar url={record?.thumbnail_url || null} />
              {record.nickname}
            </AvatarContainer>
          )}
        />
      </ReferenceField>

      <FunctionField
        sortBy='status'
        label='Status'
        render={(record) => {
          switch (record.status) {
            case 'approved':
              return (
                <ChipField
                  record={record}
                  source='status'
                  variant='outlined'
                  style={{
                    textTransform: 'capitalize',
                    borderColor: '#44b700',
                  }}
                />
              );

            case 'pending':
              return (
                <ChipField
                  record={record}
                  source='status'
                  variant='outlined'
                  style={{
                    textTransform: 'capitalize',
                    borderColor: '#eead2d',
                  }}
                />
              );

            case 'kyc declined':
              return (
                <ChipField
                  record={record}
                  source='status'
                  variant='outlined'
                  style={{
                    textTransform: 'capitalize',
                    borderColor: '#e62424',
                  }}
                />
              );

            case 'declined':
              return (
                <ChipField
                  record={record}
                  source='status'
                  variant='outlined'
                  style={{ textTransform: 'capitalize' }}
                />
              );

            default:
              return (
                <ChipField
                  record={record}
                  source='status'
                  variant='outlined'
                  style={{
                    textTransform: 'capitalize',
                    borderColor: '#eead2d',
                  }}
                />
              );
          }
        }}
      />
    </DataGridList>
  </List>
);
