import { CircularProgress, Box } from '@material-ui/core';
import { Fragment, useEffect, useState } from 'react';
import AssignStatus from './AssignStatus';
import MessageBox from './MessageBox';
import useTicketHistory from 'utils/hooks/useTicketHistory';
import { useRecordContext } from 'ra-core';

const ConversationsHistory = () => {
  const getTicketHistory = useTicketHistory();
  const [conversations, setConversations] = useState();
  const record = useRecordContext();

  useEffect(() => {
    const fetchMessages = async () => {
      if (!record?.uuid) return;

      const res = await getTicketHistory(record.uuid);
      setConversations(res);
    };
    fetchMessages().catch((error) =>
      console.error('Error fetching the conversations', error),
    );
  }, [record?.uuid]);

  if (!conversations) {
    return <CircularProgress size={18} thickness={2} />;
  }

  /**
   * Iterate through messages and check for Expert assign changes
   */
  const getContent = () => {
    const hasExpertMessage = !!conversations.find(
      (message) => message.ownerType === 'Expert',
    );
    let currentExpertId;
    return conversations.map((message, index) => {
      let assignText;
      if (message.ownerType === 'Expert') {
        if (!currentExpertId) {
          assignText = `Ticket assigned to `;
        } else if (currentExpertId !== message.userId) {
          assignText = `Ticket requeued and assigned to `;
        }
        currentExpertId = message.userId;
      }

      return (
        <Fragment key={message.createdAt}>
          {assignText && (
            <AssignStatus text={assignText} message={message} index={index} />
          )}
          <MessageBox
            fullWidth={!hasExpertMessage}
            message={message}
            index={index}
          />
        </Fragment>
      );
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: '#616161e6',
        minWidth: 300,
        maxWidth: '70%',
        margin: '0 20px',
        padding: '16px',
      }}
    >
      <Box
        sx={{
          margin: '5px 0px 10px 10px',
          color: 'rgba(255, 255, 255, 0.7)',
        }}
      >
        Conversations
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '5px',
        }}
      >
        {getContent()}
      </Box>
    </Box>
  );
};

export default ConversationsHistory;
