import DataGridList from 'components/containers/List';
import React from 'react';
import EmptyPage from 'components/Page/Empty/EmptyPage';
import { Paginator } from 'components/Paginator/Paginator';
import { ChipField, List, FunctionField, EmailField } from 'react-admin';
import { AvatarContainer, UserAvatar } from 'components/Avatars';
import RepresentativesFilter from './filter';
import ListActions from 'components/ListAction/ListActions';

export const RepresentativeList = (props) => (
  <List
    title='Representatives'
    filters={<RepresentativesFilter />}
    pagination={<Paginator />}
    perPage={15}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
    empty={<EmptyPage create />}
    actions={<ListActions listExporterType='Representatives' />}
  >
    <DataGridList show edit>
      <FunctionField
        sortBy='first_name'
        label='Name'
        render={(record) => (
          <AvatarContainer>
            <UserAvatar url={record?.thumbnail_url || null} />
            {record.first_name} {record.last_name}
          </AvatarContainer>
        )}
      />
      <EmailField source='email' />
      <FunctionField
        sortBy='status'
        label='Status'
        render={(record) => {
          switch (record.status) {
            case 'active':
              return (
                <ChipField
                  record={record}
                  source='status'
                  variant='outlined'
                  style={{
                    textTransform: 'capitalize',
                    borderColor: '#44b700',
                  }}
                />
              );

            case 'inactive':
              return (
                <ChipField
                  record={record}
                  source='status'
                  variant='outlined'
                  style={{
                    textTransform: 'capitalize',
                    borderColor: '#e62424',
                  }}
                />
              );

            case 'pending':
              return (
                <ChipField
                  record={record}
                  source='status'
                  variant='outlined'
                  style={{
                    textTransform: 'capitalize',
                    borderColor: '#e62424',
                  }}
                />
              );

            case 'suspended':
              return (
                <ChipField
                  record={record}
                  source='status'
                  variant='outlined'
                  style={{ textTransform: 'capitalize' }}
                />
              );

            default:
              break;
          }
        }}
      />
    </DataGridList>
  </List>
);
