import MyLayout from 'components/AppBar/Layout';
import Dashboard from 'components/Dashboard/Dashboard';
import BackdropLoader from 'components/Loaders/BackdropLoader';
import Menu from 'components/Menu/Menu';
import Login from 'components/Login/Login';
import dataProvider from 'providers/Data';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import { useEffect, useState } from 'react';
import { Admin } from 'react-admin';
import { useHistory } from 'react-router-dom';
import ExpertsRoutes from 'resources/Experts';
import BrandsRoutes from 'resources/Brands';
import GeoRoutes from 'resources/Geo';
import { ThemeContext } from 'themes/ThemeContext';
import { MyDarkTheme, MyLightTheme } from 'themes/Themes';
import './App.css';
import UsersRoutes from 'resources/Users';
import SystemRoutes from 'resources/System';
import authProvider from 'providers/Authentication';
import LocalizationRoutes from 'resources/Localization';
import NotificationsRoutes from 'resources/Notifications';
import RepresentativesRoutes from 'resources/Representatives';
import FundingRoutes from 'resources/Funding';
import MonitoringRoutes from 'resources/Monitoring';
import SeasonsRoutes from 'resources/Seasons';

let previousUrlPath;

const App = () => {
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(false);
  const [theme, setTheme] = useState(
    localStorage.getItem('theme') ? localStorage.getItem('theme') : 'dark',
  );

  // if the current page is home (dashboard), don't show the loader:
  useEffect(() => {
    previousUrlPath = history.location.hash;
    if (
      history.location.hash.charAt(history.location.hash.length - 1) === '/'
    ) {
      setShowLoader(false);
    } else {
      setShowLoader(true);
    }
  }, []); // eslint-disable-line

  // whenever the url changes, show loader (unless it is the dashboard):
  useEffect(() => {
    return history.listen(() => {
      // the following code prevents the bug where the user
      // clicks the browser back button from a create/show/edit page and add a filter:
      if (
        previousUrlPath.split('').filter((letter) => letter === '/').length >=
          2 &&
        history.location.hash.split('').filter((letter) => letter === '/')
          .length < 2
      ) {
        window.location.reload();
      }
      previousUrlPath = history.location.hash;

      // here we are showing the loaßder if the url is not home (dashboard):
      if (
        history.location.hash.charAt(history.location.hash.length - 1) === '/'
      ) {
        setShowLoader(false);
      } else {
        setShowLoader(true);
      }
    });
  }, [history]);

  const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', {
    allowMissing: true,
  });
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <Admin
        dashboard={Dashboard}
        layout={MyLayout}
        theme={theme === 'dark' ? MyDarkTheme : MyLightTheme}
        authProvider={authProvider}
        dataProvider={dataProvider}
        loginPage={Login}
        menu={Menu}
        i18nProvider={i18nProvider}
      >
        {showLoader ? <BackdropLoader /> : <></>}

        {ExpertsRoutes}
        {BrandsRoutes}
        {RepresentativesRoutes}
        {LocalizationRoutes}
        {GeoRoutes}
        {FundingRoutes}
        {UsersRoutes}
        {SystemRoutes}
        {NotificationsRoutes}
        {MonitoringRoutes}
        {SeasonsRoutes}
      </Admin>
    </ThemeContext.Provider>
  );
};

export default App;
