import React from 'react';
import {
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
} from 'react-admin';
import { DebouncedTextField } from 'components/Fields';
import FilterContainer from 'components/containers/Filter';

const ReviewsFilter = (props) => (
  <FilterContainer {...props} variant='standard' margin='normal'>
    <SelectInput
      source='status'
      choices={[
        { id: 'pending-approval', name: 'Pending' },
        { id: 'approved', name: 'Approved' },
        { id: 'rejected', name: 'Rejected' },
      ]}
    />

    <ReferenceInput
      source='reviewable_id'
      reference='experts'
      label='Expert'
      perPage={25}
      filterToQuery={(searchText) =>
        searchText
          ? {
              id: +searchText,
              nickname: searchText,
              _autocomplete: true,
            }
          : ''
      }
    >
      <AutocompleteInput
        matchSuggestion={() => true}
        optionText={(choice) => `${choice.nickname} (${choice.id})`}
      />
    </ReferenceInput>

    <ReferenceInput
      source='reviewer_id'
      reference='users'
      label='User'
      perPage={25}
      filterToQuery={(searchText) =>
        searchText ? { nickname: searchText, _autocomplete: true } : ''
      }
    >
      <AutocompleteInput
        matchSuggestion={() => true}
        optionText={(self) => (self ? `${self.nickname} (${self.id})` : '')}
      />
    </ReferenceInput>

    <TextInput label='Rating' source='rating' />

    <DebouncedTextField label='Comment' source='comment' />
  </FilterContainer>
);

export default ReviewsFilter;
