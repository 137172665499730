import { Box } from '@material-ui/core';
import BoldText from 'components/common/BoldText';
import RoundedBorderSection from 'components/common/RoundedBorderSection';
import WarningIcon from 'assets/svgs/WarningIcon';

const WarningSection = (props) => {
  const { brandNFTAddress } = props;

  return (
    <RoundedBorderSection color={'#FF5C00'}>
      <Box
        sx={{
          display: 'flex',
          columnGap: '20px',
          alignItems: 'center',
        }}
      >
        <WarningIcon sx={{ color: '#FF5C00', fontSize: 30 }} />
        <Box
          sx={{
            lineHeight: '30px',
          }}
        >
          <Box>
            {`Ensure your sending address is: `}
            <BoldText>{brandNFTAddress}</BoldText>
          </Box>
          The sending address needs to contain your brand’s NFT to complete the
          funding of the rewards pool
        </Box>
      </Box>
    </RoundedBorderSection>
  );
};

export default WarningSection;
