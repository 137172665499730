import React from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import { ViewTitle } from '../../../components/AppBar/Titles';
import {
  Show,
  SimpleShowLayout,
  TextField,
  FunctionField,
  ImageField,
  EmailField,
} from 'react-admin';

export const AdminUsersShow = (props) => (
  <Show title={<ViewTitle source='email' />} {...props}>
    <SimpleShowLayout>
      <TextField source='id' label='ID' />
      <TextField source='first_name' label='First Name' />
      <TextField source='last_name' label='Last Name' />
      <EmailField source='email' label='Email(s)' />

      <ImageField source='thumbnail_url' label='Thumbnail' />
      <FunctionField
        label='Created At'
        source='created_at'
        render={(record) =>
          record.created_at && (
            <Moment format='MM/DD/YYYY'>{moment(record.created_at)}</Moment>
          )
        }
      />

      <FunctionField
        label='Updated At'
        source='updated_at'
        render={(record) =>
          record.updated_at && (
            <Moment format='MM/DD/YYYY'>{moment(record.updated_at)}</Moment>
          )
        }
      />
    </SimpleShowLayout>
  </Show>
);
