import tagsIcon from '@material-ui/icons/Label';
import { ExpertTagsList } from './list';
import { ExpertTagsCreate } from './create';
import { ExpertTagsShow } from './show';

export default {
  key: 'expert_tags',
  name: 'expert_tags',
  options: { label: 'Tags', menuParent: 'expert' },
  icon: tagsIcon,
  list: ExpertTagsList,
  show: ExpertTagsShow,
  create: ExpertTagsCreate,
};
