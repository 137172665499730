import React from 'react';
import styles from './svg.module.css';

const BrandFundingIcon = () => {
  return (
    <svg
      width='22'
      height='16'
      viewBox='0 0 22 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={styles.iconRoot}
    >
      <path
        d='M12.5 9.5C11.6667 9.5 10.9583 9.20833 10.375 8.625C9.79167 8.04167 9.5 7.33333 9.5 6.5C9.5 5.66667 9.79167 4.95833 10.375 4.375C10.9583 3.79167 11.6667 3.5 12.5 3.5C13.3333 3.5 14.0417 3.79167 14.625 4.375C15.2083 4.95833 15.5 5.66667 15.5 6.5C15.5 7.33333 15.2083 8.04167 14.625 8.625C14.0417 9.20833 13.3333 9.5 12.5 9.5ZM4.5 13C4.08333 13 3.72917 12.8542 3.4375 12.5625C3.14583 12.2708 3 11.9167 3 11.5V1.5C3 1.08333 3.14583 0.729167 3.4375 0.4375C3.72917 0.145833 4.08333 0 4.5 0H20.5C20.9167 0 21.2708 0.145833 21.5625 0.4375C21.8542 0.729167 22 1.08333 22 1.5V11.5C22 11.9167 21.8542 12.2708 21.5625 12.5625C21.2708 12.8542 20.9167 13 20.5 13H4.5ZM7 11.5H18C18 10.8 18.2417 10.2083 18.725 9.725C19.2083 9.24167 19.8 9 20.5 9V4C19.8 4 19.2083 3.75833 18.725 3.275C18.2417 2.79167 18 2.2 18 1.5H7C7 2.2 6.75833 2.79167 6.275 3.275C5.79167 3.75833 5.2 4 4.5 4V9C5.2 9 5.79167 9.24167 6.275 9.725C6.75833 10.2083 7 10.8 7 11.5ZM19 16H1.5C1.08333 16 0.729167 15.8542 0.4375 15.5625C0.145833 15.2708 0 14.9167 0 14.5V3H1.5V14.5H19V16ZM4.5 11.5V1.5V11.5Z'
        fill='white'
      />
    </svg>
  );
};
export default BrandFundingIcon;
