/**
 * Custom export options for resources
 */
export const CustomExportOptions = {
  Applications: {
    relatedObjectsOptions: [
      {
        resource: 'experts',
        fieldId: 'expert_id',
        fieldsToInclude: ['name', 'email', 'public_id'],
        fieldsAlias: {
          public_id: 'wallet',
        },
      },
    ],
    fieldsToInclude: [
      'id',
      'expert_id',
      'brand_id',
      'about',
      'reason',
      'status',
      'timezone',
      'discord',
      'discourse',
      'github',
      'linkedin',
      'twitter',
    ],
  },
};
