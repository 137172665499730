import React from 'react';
import { EditTitle } from '../../../components/AppBar/Titles';
import { EditToolbar } from '../../../components/Toolbar/EditToolbar';
import { Edit, SimpleForm, SelectInput, required } from 'react-admin';

export const BrandingsEdit = (props) => (
  <Edit title={<EditTitle source='name' />} {...props}>
    <SimpleForm variant='standard' margin='normal' toolbar={<EditToolbar />}>
      <SelectInput
        source='status'
        validate={required()}
        fullWidth
        choices={[
          { id: 'active', name: 'Activate' },
          { id: 'suspended', name: 'Suspend' },
          { id: 'banned', name: 'ban' },
        ]}
      />
    </SimpleForm>
  </Edit>
);
