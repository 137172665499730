import { FunctionField, ReferenceField } from 'react-admin';

/**
 * Renders the Active Expert from the assignments list
 */
const ActiveExpert = ({ record }) => (
  <>
    {record.assignments
      .filter((a) => a.status === 'active')
      .map((item) => {
        return (
          <ReferenceField
            key={item}
            record={item}
            source='expert_id'
            reference='experts'
            label='Expert'
            link={(record, reference) =>
              `/${reference}/${record.expert_id}/show`
            }
          >
            <FunctionField
              render={(record) => (
                <>
                  {record.nickname} ({record.id})
                </>
              )}
            />
          </ReferenceField>
        );
      })}
  </>
);

export default ActiveExpert;
