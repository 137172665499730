import React from 'react';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';
import { Create, SimpleForm, TextInput, required } from 'react-admin';

export const CitiesCreate = (props) => (
  <Create {...props} title='Create Cities'>
    <SimpleForm variant='standard' margin='normal' toolbar={<CreateToolbar />}>
      <TextInput source='name' validate={required()} fullWidth />
    </SimpleForm>
  </Create>
);
