import UsersIcon from '@material-ui/icons/AccountCircleRounded';
import { UsersList } from './list';
import { UsersShow } from './show';
export default {
  name: 'users',
  options: { label: 'Main', menuParent: '_users' },
  icon: UsersIcon,
  list: UsersList,
  show: UsersShow,
};
