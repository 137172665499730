import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Welcome from './Welcome';
import { useVersion } from 'react-admin';
import config from '../../config';
import 'react-datepicker/dist/react-datepicker.css';

const Dashboard = () => {
  const [, setData] = useState({});
  const version = useVersion();
  const [startTime] = useState(() => {
    const date = new Date();
    return moment(new Date(date.getFullYear(), date.getMonth(), 1))
      .startOf('day')
      .toDate();
  });
  const [endTime] = useState(moment().endOf('day').toDate());

  const getData = async (startTime, endTime) => {
    const token = localStorage.getItem('token');
    return await fetch(
      `${config.API_URL}/resources/stats?start_time=${
        startTime ? encodeURIComponent(startTime / 1000) : ''
      }&end_time=${endTime ? encodeURIComponent(endTime / 1000) : ''}`,
      {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          'X-Auth-Token': token,
        }),
      },
    ).then((res) => res.json());
  };

  const fetchData = async (startTime, endTime) => {
    try {
      const { data } = await getData(startTime, endTime);
      setData(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchData(startTime, endTime).catch((error) =>
      console.log('Error fetching data', error),
    );
  }, [version, startTime, endTime]); // eslint-disable-line

  return (
    <>
      <Welcome />
    </>
  );
};

export default Dashboard;
