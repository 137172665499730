import brandingsIcon from '@material-ui/icons/Label';
import { BrandingsList } from './list';
import { BrandingsCreate } from './create';
import { BrandingsEdit } from './edit';
import { BrandingsShow } from './show';

export default {
  name: 'brandings',
  options: { label: 'Brandings', menuParent: 'expert' },
  icon: brandingsIcon,
  list: BrandingsList,
  create: BrandingsCreate,
  edit: BrandingsEdit,
  show: BrandingsShow,
};
