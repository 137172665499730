import LocalizationIcon from '@material-ui/icons/LanguageRounded';

import { Resource } from 'react-admin';
import timezoneViews from './Timezone';
import languagesView from './Language';

const LocalizationRoutes = [
  <Resource
    key='localization'
    name='localization'
    icon={LocalizationIcon}
    options={{ label: 'Localization', isMenuParent: true }}
  />,
  <Resource key='timezone-views' {...timezoneViews} />,
  <Resource key='languages-view' {...languagesView} />,
];
export default LocalizationRoutes;
