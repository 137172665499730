import { Resource } from 'react-admin';

const SeasonsRoutes = [
  /**
   * We're just registering this route to be able to use <ReferenceField reference="seasons"> from other components
   * If in the future we have a Seasons section we should remove the intent="registration"
   */
  <Resource key='seasons' name='seasons' intent='registration' />,
];
export default SeasonsRoutes;
