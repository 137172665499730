import { httpClient } from 'providers/Data';
import config from 'config';
import { useNotify, useUpdateLoading } from 'ra-core';

const useTicketHistory = () => {
  const { startLoading, stopLoading } = useUpdateLoading();
  const notify = useNotify();

  const getTicketHistory = async (ticketId) => {
    startLoading();
    let res = [];
    try {
      const { json } = await httpClient(
        `${config.API_URL}/resources/tickets/${ticketId}/history`,
      );
      res = json;
    } catch (e) {
      notify(`Error retrieving messages history`, { type: 'error' });
      console.error(
        `Error retrieving messages history for ticket with id ${ticketId}. Error:`,
        e,
      );
    }

    stopLoading();
    return res;
  };

  return getTicketHistory;
};

export default useTicketHistory;
