import moment from 'moment';
import { CustomExportOptions } from './ExportOptions';

export const HelperListExporter = async (data, fetchRelatedRecords, type) => {
  const customOptions = CustomExportOptions[type];
  const hasCustomExport = !!customOptions;

  const relatedObjectsInfo = {};
  if (hasCustomExport) {
    for (const options of customOptions.relatedObjectsOptions) {
      const objs = await fetchRelatedRecords(
        data,
        options.fieldId,
        options.resource,
      );
      relatedObjectsInfo[options.resource] = objs;
    }
  }

  const exportableRecords = data.map((record) => {
    const { created_at, updated_at, ...exportableFields } = record;

    const result = hasCustomExport ? {} : exportableFields;
    if (hasCustomExport) {
      // Set fields from related objects
      customOptions.relatedObjectsOptions.forEach((option) => {
        const relatedObject =
          relatedObjectsInfo[option.resource][exportableFields[option.fieldId]];
        option.fieldsToInclude.forEach((field) => {
          const nameToDefine = option.fieldsAlias[field] || field;
          result[nameToDefine] = relatedObject[field];
        });
      });
      // Set needed fields from main object
      customOptions.fieldsToInclude.forEach((field) => {
        console.log(field);
        result[field] = exportableFields[field];
      });
    }

    result.created_at =
      created_at && moment(created_at)._d.toLocaleDateString('en-US');
    result.updated_at =
      updated_at && moment(updated_at)._d.toLocaleDateString('en-US');

    return result;
  });

  return {
    data: exportableRecords,
    headers: [],
    type,
  };
};
