import React from 'react';
import { CreateToolbar } from '../../../components/Toolbar/CreateToolbar';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { ImageUploaderWithPreview } from 'components/common/ImageUploaderWithPreview';
import { FileUploaderPlaceholder } from 'components/ImageContainer/FilePlaceholder/FileUploaderPlaceholder';

export const ExpertsCreate = (props) => {
  return (
    <Create {...props} title='Create Expert'>
      <SimpleForm
        variant='standard'
        margin='normal'
        toolbar={<CreateToolbar />}
      >
        <ReferenceInput
          validate={required()}
          source='brand_id'
          reference='brands'
          label='Brand'
          perPage={10}
          filterToQuery={(searchText) => ({
            id: parseInt(searchText),
            name: searchText,
            _autocomplete: true,
          })}
          sort={{ field: 'id', order: 'ASC' }}
          fullWidth
        >
          <AutocompleteInput
            matchSuggestion={() => true}
            optionText={(choice) =>
              choice?.id ? `${choice.name} (${choice.id})` : 'None'
            }
          />
        </ReferenceInput>
        <TextInput source='name' validate={required()} fullWidth />
        <TextInput source='email' validate={required()} fullWidth />
        <TextInput
          label='Wallet'
          source='address'
          validate={required()}
          fullWidth
        />
        <ImageUploaderWithPreview
          source='thumbnail'
          accept='image/*'
          label='Profile Picture'
          validate={required()}
          placeholder={<FileUploaderPlaceholder />}
        />
      </SimpleForm>
    </Create>
  );
};
