import React from 'react';
import styles from './svg.module.css';
const Page = () => {
  return (
    <svg
      id='Capa_1'
      enableBackground='new 0 0 512 512'
      className={styles.iconRoot}
      height='512'
      viewBox='0 0 512 512'
      width='512'
    >
      <g>
        <g>
          <path d='m397.621 62.61c0-5.523-4.477-10-10-10h-.057c-5.523 0-9.972 4.477-9.972 10s4.505 10 10.028 10 10.001-4.477 10.001-10z' />
          <path d='m451.562 62.61c0-5.523-4.477-10-10-10h-.057c-5.523 0-9.972 4.477-9.972 10s4.505 10 10.028 10 10.001-4.477 10.001-10z' />
          <path d='m343.708 62.61c0-5.523-4.477-10-10-10h-.057c-5.523 0-9.972 4.477-9.972 10s4.505 10 10.028 10 10.001-4.477 10.001-10z' />
          <path d='m60.438 62.61c0 5.523 4.477 10 10 10h150.116c5.523 0 10-4.477 10-10s-4.477-10-10-10h-150.116c-5.522 0-10 4.477-10 10z' />
          <path d='m479.714 0h-312.647c-5.523 0-10 4.477-10 10s4.477 10 10 10h312.647c6.662 0 12.081 5.407 12.081 12.053v73.167h-471.59v-73.167c0-6.646 5.407-12.053 12.053-12.053h45.012c5.523 0 10-4.477 10-10s-4.477-10-10-10h-45.012c-17.674 0-32.053 14.379-32.053 32.053v447.865c0 17.69 14.379 32.082 32.053 32.082h447.456c17.689 0 32.081-14.392 32.081-32.082v-447.865c0-17.674-14.392-32.053-32.081-32.053zm0 492h-447.456c-6.646 0-12.053-5.42-12.053-12.082v-354.698h471.59v354.698c0 6.662-5.42 12.082-12.081 12.082z' />
          <path d='m93.653 382.613c-22.972 0-41.662 18.689-41.662 41.662 0 22.957 18.689 41.634 41.662 41.634 22.957 0 41.633-18.677 41.633-41.634 0-22.972-18.676-41.662-41.633-41.662zm0 63.297c-11.944 0-21.662-9.705-21.662-21.634 0-11.944 9.717-21.662 21.662-21.662 11.928 0 21.633 9.718 21.633 21.662 0 11.929-9.704 21.634-21.633 21.634z' />
          <path d='m201.875 382.613c-22.957 0-41.633 18.689-41.633 41.662 0 22.957 18.677 41.634 41.633 41.634s41.633-18.677 41.633-41.634c0-22.972-18.676-41.662-41.633-41.662zm0 63.297c-11.929 0-21.633-9.705-21.633-21.634 0-11.944 9.705-21.662 21.633-21.662s21.633 9.718 21.633 21.662c0 11.929-9.704 21.634-21.633 21.634z' />
          <path d='m310.097 382.613c-22.957 0-41.633 18.689-41.633 41.662 0 22.957 18.677 41.634 41.633 41.634 22.972 0 41.662-18.677 41.662-41.634-.001-22.972-18.69-41.662-41.662-41.662zm0 63.297c-11.929 0-21.633-9.705-21.633-21.634 0-11.944 9.705-21.662 21.633-21.662 11.944 0 21.662 9.718 21.662 21.662-.001 11.929-9.718 21.634-21.662 21.634z' />
          <path d='m418.347 382.613c-22.972 0-41.662 18.689-41.662 41.662 0 22.957 18.689 41.634 41.662 41.634 22.957 0 41.633-18.677 41.633-41.634 0-22.972-18.677-41.662-41.633-41.662zm0 63.297c-11.944 0-21.662-9.705-21.662-21.634 0-11.944 9.717-21.662 21.662-21.662 11.929 0 21.633 9.718 21.633 21.662 0 11.929-9.705 21.634-21.633 21.634z' />
          <path d='m52.027 333.874c.36 5.17 4.778 9.298 9.979 9.298h223.813c5.457 0 10.014-4.553 10.014-10v-169.254c0-5.523-4.477-10-10-10h-223.841c-5.523 0-10 4.477-10 10v169.253c.012.235.023.469.035.703zm165.777-10.702-18.631-29.364 24.923-39.259 43.548 68.623zm-23.686 0h-113.935l56.978-89.767zm81.715-149.254v124.833l-43.292-68.22c-1.834-2.891-5.02-4.642-8.442-4.642-3.423 0-6.608 1.751-8.443 4.641l-28.324 44.616-41.725-65.76c-1.834-2.891-5.02-4.642-8.443-4.643 0 0 0 0-.001 0-3.423 0-6.608 1.751-8.443 4.641l-56.729 89.373v-124.839z' />
          <path d='m338.045 173.918h111.538c5.523 0 10-4.477 10-10s-4.477-10-10-10h-111.538c-5.523 0-10 4.477-10 10s4.477 10 10 10z' />
          <path d='m338.045 229.334h111.538c5.523 0 10-4.477 10-10s-4.477-10-10-10h-111.538c-5.523 0-10 4.477-10 10s4.477 10 10 10z' />
          <path d='m338.045 284.723h111.538c5.523 0 10-4.477 10-10s-4.477-10-10-10h-111.538c-5.523 0-10 4.477-10 10s4.477 10 10 10z' />
          <path d='m338.045 340.11h111.538c5.523 0 10-4.477 10-10s-4.477-10-10-10h-111.538c-5.523 0-10 4.477-10 10s4.477 10 10 10z' />
          <path d='m122.168 20h.057c5.523 0 9.972-4.477 9.972-10s-4.505-10-10.028-10-10 4.477-10 10 4.477 10 9.999 10z' />
        </g>
      </g>
    </svg>
  );
};
export default Page;
