import { Resource } from 'react-admin';
import BrandsIcon from '@material-ui/icons/LocalOfferRounded';
import brandViews from './Brand';
import tagViews from './Tag';

const BrandsRoutes = [
  <Resource
    key='brand'
    name='brand'
    icon={BrandsIcon}
    options={{ label: 'Brands', isMenuParent: true }}
  />,
  <Resource key='brand-views' {...brandViews} />,
  <Resource key='tag-views' {...tagViews} />,
];
export default BrandsRoutes;
