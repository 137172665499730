import { useState, useEffect } from 'react';
import { Contract, getDefaultProvider } from 'ethers';

function useSmartContract(abi, address, rpcURL) {
  const [isLoading, setIsLoading] = useState(true);
  const [smartContract, setSmartContract] = useState(null);

  useEffect(() => {
    const fetchSmartContract = async () => {
      if (!abi || !address || !rpcURL) {
        return;
      }

      const provider = getDefaultProvider(rpcURL);
      const smartContract = new Contract(address, abi.default, provider);

      setSmartContract(smartContract);
      setIsLoading(false);
    };
    fetchSmartContract().catch((error) =>
      console.error('Error fetching the smart contracts', error),
    );
  }, [abi, address, rpcURL]);

  return { smartContract, isLoading };
}

export default useSmartContract;
